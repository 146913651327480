import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './font-awesome'
import router from '@/router/index'
/* import pinia from '@/stores' */
import { createPinia } from 'pinia'
import i18n from '@/i18n'
import { App } from 'vue'
import clickOutside from '@/directives/clickOutside'
import vSelect from 'vue-select'

function initVue(app: App, noRouter = false) {
	if (!noRouter) {
		app.use(router)
	}
	const pinia = createPinia()
    app.use(pinia)

	app.use(i18n)
	app.directive('clickOutside', clickOutside)
	app.component('font-awesome-icon', FontAwesomeIcon)
	app.component('VSelect', vSelect)
}

export default initVue
