<template>
	<div class="tag-input-wrapper">
		<div class="tag-input">
			<slot />
			<font-awesome-icon
				icon="fa-regular fa-xmark"
				class="tag-input__icon fa-lg text-brand-light-blue cursor-pointer"
				data-qa="searchConfig-remove-tag"
				@click="() => emit('close')"
			/>
		</div>
	</div>
</template>

<script lang="ts" setup>
const emit = defineEmits<{(e: 'close'): void}>()
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars/colors';

.tag-input-wrapper {
	display: inline-block;
}

.tag-input {
	font-size: 16px;
	font-weight: 600;
	margin-right: 10px;
	margin-top: 10px;
	padding: 10px 10px;
	border-radius: 5px;
	background-color: #d6f0fc;
	display: inline-flex;
	align-items: center;
	flex-direction: row;
	color: $clr-brand-darkest-blue;

	&__icon {
		margin-left: auto;
	}
}
</style>
