import { library } from '@fortawesome/fontawesome-svg-core'

import {
	faBuilding as faBuilding,
	faArrowRotateRight as faArrowRotateRight,
	faFloppyDisk as faFloppyDisk,
	faSlidersH as faSlidersH,
	faInbox as faInbox,
	faEnvelope as faEnvelope,
	faEnvelopeOpen as faEnvelopeOpen,
	faTrashCan as faTrashCan,
} from '@fortawesome/pro-light-svg-icons'

import {
	faXmark as faXmark,
	faClose as faClose,
	faInfoCircle as faInfoCircle,
	faAngleUp as faAngleUp,
	faAngleDown as faAngleDown,
} from '@fortawesome/pro-regular-svg-icons'

import {
	faPennant as faPennant,
	faTimes as faTimes,
	faArrowRight as faArrowRight,
	faArrowLeft as faArrowLeft,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
	faArrowRotateRight,
	faBuilding,
	faClose,
	faEnvelope,
	faEnvelopeOpen,
	faFloppyDisk,
	faInbox,
	faInfoCircle,
	faSlidersH,
	faTrashCan,
	faXmark,
	faAngleUp,
	faAngleDown,
	faPennant,
	faTimes,
	faArrowRight,
	faArrowLeft,
)
