import {Nachricht} from '@/types/messageBox/messages'
import {isTransactionPayload} from '@/types/InterfaceGuards'
import {TransactionPayload, IkarosPayload} from '@/models/Messages/Inkasso'

export abstract class Base implements Omit<Nachricht, 'nachrichtType'|'kategorie_gesetzt_von'> {
	nachrichtId: string
	creationTime: string
	isRead: boolean
	memberId: string
	memberUserId: string
	templateId: string
	givenName: string
	surName: string
	keywords: string
	payload: any
	kategorie: string
	kategorieGesetztVon?: string
	msgTemplate?: string
	readtime?: string

	protected constructor(nachricht: Nachricht) {
		this.nachrichtId = nachricht.nachrichtId
		this.creationTime = nachricht.creationTime
		this.isRead = nachricht.isRead
		this.memberId = nachricht.memberId
		this.memberUserId = nachricht.memberUserId
		this.templateId = nachricht.templateId
		this.givenName = nachricht.givenName
		this.surName = nachricht.surName
		this.keywords = nachricht.keywords
		this.payload = JSON.parse(nachricht.payload)
		this.kategorie = nachricht.kategorie ?? 'KEINE_KATEGORIE'
		this.kategorieGesetztVon = nachricht.kategorie_gesetzt_von
		this.msgTemplate = nachricht.msgTemplate
		this.readtime = nachricht.readtime
	}

	getTemplate(templateId: string, availableTypes: Array<{templateId: string, template: string}>) {
		return availableTypes.find((item) => templateId === item.templateId)?.template ?? 'not_found'
	}

	getTransactionId(payload: IkarosPayload|TransactionPayload) {
		return isTransactionPayload(payload) ? payload.transactionId ?? undefined : undefined
	}

	getHeadlineText(payload: any) {
		 return [payload?.displayName, payload?.postalAdress?.city].filter(Boolean).join(', ')
	}

}
