<template>
	<div>
		<hr />
		<!-- eslint-disable vue/no-v-html -->
		<p
			data-qa="messageFooter"
			v-html="$t('messageFooter')"
		/>
		<!-- eslint-enable -->
	</div>
</template>

<style lang="scss" scoped>
hr {
	margin: 3rem -2rem 1rem;
	border-top: 1px solid lightgrey;
}
</style>
