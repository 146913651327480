<template>
	<div>
		<component
			:is="components[locale as keyof ContainerOfAllLocales]"
			:template-vars="getNachricht"
		/>
	</div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, shallowRef } from 'vue'

import de_DE from '../templates/de_DE/LetterReturnsTemplate.vue'
import de_AT from '../templates/de_DE/LetterReturnsTemplate.vue'
import en_US from '../templates/en_US/LetterReturnsTemplate.vue'
import fr_FR from '../templates/fr_FR/LetterReturnsTemplate.vue'
import fr_LU from '../templates/fr_FR/LetterReturnsTemplate.vue'
import {Inkasso} from '@/models/Messages/Inkasso'
import {InkassoTemplateVars} from '@/components/molecules/Message/messageTypes/Inkasso/types'
import {ContainerOfAllLocales} from '@/types/ContainerOfAllLocales'

const { locale, t } = useI18n()
const components = shallowRef<ContainerOfAllLocales>({
	de_DE,
	de_AT,
	en_US,
	fr_FR,
	fr_LU,
})

const props = defineProps<{
	nachricht: Inkasso,
	vc: {name: string}
}>()

const getNachricht = computed(() => {
	return {
		collectionFileNo: props.nachricht.attributes?.collectionFileNo,
		displayName: props.nachricht.attributes?.displayName,
    linkToCollectionFileDetails: props.nachricht.getLinkToCollectionFileDetails(t),
		participantTo: t(
			'timelineEntryType.participantType.' + props.nachricht.attributes?.participantTo + '.to.text',
		),
		vcName: props.vc.name,
	} as InkassoTemplateVars
})
</script>
