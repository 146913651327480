<template>
	<div class="posteingang-messageTools-tools__pagination">
		<ul
			v-if="gesamtAnzahlNachrichten > 0"
			data-qa="posteingang-messageTools-tools__pagination-list"
			class="pagination"
		>
			<li
				class="arrow-left pagination-arrow"
				:class="{ disabled: pagination.pageStartIndex === 0 }"
			>
				<a
					href="#"
					@click.prevent="prevData"
				>
					<font-awesome-icon icon="fa-solid fa-arrow-left" />
				</a>
			</li>
			<li
				class="first page-number"
				:class="{ active: pagination.pageStartIndex === 0 }"
			>
				<a
					class="page-number-link"
					href="#"
					@click.prevent="changePage(1)"
				>
					1
				</a>
			</li>
			<li
				v-if="getLeftSeparator"
				class="separator"
			/>
			<li
				v-for="(page, index) in pages"
				:key="index"
				class="page-number"
				:class="{ active: page === pagination.pageStartIndex + 1 }"
			>
				<a
					class="page-number-link"
					href="#"
					@click.prevent="changePage(page)"
				>
					{{ page }}
				</a>
			</li>
			<li
				v-if="getRightSeparator"
				class="separator"
			/>
			<li
				v-if="maxPageStartIndex > 0"
				class="last page-number"
				:class="{ active: maxPageStartIndex === pagination.pageStartIndex }"
			>
				<a
					class="page-number-link"
					href="#"
					@click.prevent="changePage(maxPageStartIndex + 1)"
				>
					{{ maxPageStartIndex + 1 }}
				</a>
			</li>
			<li
				class="arrow-right pagination-arrow"
				:class="{ disabled: pagination.pageStartIndex === maxPageStartIndex }"
			>
				<a
					href="#"
					@click.prevent="nextData"
				>
					<font-awesome-icon icon="fa-solid fa-arrow-right" />
				</a>
			</li>
		</ul>
	</div>
</template>

<script lang="ts" setup>
import { useMessagesStore } from '@/stores/messages'
import { computed, watch } from 'vue'
import { scrollTo } from '@/helper/scrollTo'
import {storeToRefs} from 'pinia'

const { pagination } = storeToRefs(useMessagesStore())
const props = defineProps({
	gesamtAnzahlNachrichten: {
		type: Number,
		default: 0,
	},
})

const getRightSeparator = computed(() => {
	return (
		pagination.value.pageStartIndex + 1 <= maxPageStartIndex.value + 1 - 4 &&
		maxPageStartIndex.value + 1 > 9
	)
})

const getLeftSeparator = computed(() => {
	return pagination.value.pageStartIndex + 1 > 4 && maxPageStartIndex.value + 1 > 9
})

const maxPageStartIndex = computed(() => {
	return Math.ceil(props.gesamtAnzahlNachrichten / pagination.value.pageSize) - 1
})

/**
 * Berechnet, welche Seiten in der Pagination angezeigt werden sollen (Außer die erste und letzte Seite, da sie immer da sind). Es werden immer neun Elemente in der Pagination angezeigt, außer es gibt weniger als oder genau neun Seiten. Dann werden alle Seiten angezeigt.
 * @returns {*[]} Sortierte Liste an Seitennummern
 */

const pages = computed(() => {
	let selectedPage = pagination.value.pageStartIndex + 1
	let lastPage = maxPageStartIndex.value + 1
	let pages = []

	//Gehe alle Seiten durch (erste und letzte Seite ausgenommen, weil die immer da sind)
	for (let i = 2; i < lastPage; i++) {
		//Wenn es maximal 9 Seiten gibt, zeige alle an
		if (lastPage <= 9) {
			pages.push(i)
		}
		//Bsp: 1 2 3 4 5 6 7 ... 10
		else if (selectedPage < 5) {
			if (i <= 7) pages.push(i)
			else break
		}
		//Bsp: 1 ... 94 95 96 97 98 99 100
		else if (selectedPage > lastPage - 4) {
			if (i >= lastPage - 6) pages.push(i)
		}
		//Bsp: 1 ... 6 7 8 9 10 ... 100
		else if (i >= selectedPage - 2 && i <= selectedPage + 2) {
			pages.push(i)
		}
	}

	//Return sortierte Liste an Seitennummern
	return pages.sort((a, b) => a - b)
})

watch(props, (newVal, oldVal) => {
	if (newVal !== oldVal) {
		pagination.value.pageStartIndex = 0
	}
})

function nextData() {
	if (pagination.value.pageStartIndex === maxPageStartIndex.value) {
		return
	}
	pagination.value.pageStartIndex = pagination.value.pageStartIndex + 1
	scrollTo('[data-qa="posteingang-messageTools"]')
}

function prevData() {
	if (pagination.value.pageStartIndex < 0) {
		return
	}
	pagination.value.pageStartIndex =
		pagination.value.pageStartIndex !== 0 ? pagination.value.pageStartIndex - 1 : 0
	scrollTo('[data-qa="posteingang-messageTools"]')
}

function changePage(page: number) {
	pagination.value.pageStartIndex = page - 1
	scrollTo('[data-qa="posteingang-messageTools"]')
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars/vars';
@import '@/assets/scss/mixins/media-breakpoints';

.selectActive {
	.posteingang-messageTools-tools__pagination {
		display: none;

		@include media-up(md) {
			display: flex;
		}
	}
}

.posteingang-messageTools-tools__pagination {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: flex-end;
	color: $clr-brand-dark-grey;

	.pagination {
		display: block;
		align-items: center;
		font-weight: 400;
		flex-wrap: wrap;
		overflow: hidden;
		list-style-type: none;
		margin-bottom: 0;
	}

	li {
		display: inline-block;
		position: relative;
		text-align: center;
		letter-spacing: 0.4px;
		padding: 0 0 0 0.35rem;
		font-weight: 400;

		&.separator {
			min-width: calc(30px + 0.35rem);

			&::before {
				content: '...';
				padding: 0 5px;
				font-weight: 700;
			}
		}

		&.page-number {
			&:hover {
				a {
					color: $white;
					background-color: $clr-brand-blue;
				}
			}
		}

		a {
			min-width: 30px;
			height: 30px;
			line-height: 30px;
			padding: 0 5px;
			display: block;
			color: $clr-brand-darkest-grey;
			font-weight: 600;
			font-size: 1.1rem;
			transition:
				color 0.3s,
				background-color 0.3s;

			&:hover i {
				color: $clr-brand-blue;
			}
		}

		&.pagination-arrow {
			&.arrow-left {
				padding: 0;
			}

			&.arrow-right {
				margin: 0;
			}

			a {
				color: $clr-brand-dark-grey;
				padding: 0;

				i {
					vertical-align: sub;

					&.icon-arrow-prev:before {
						content: '\e904';
					}

					&.icon-arrow-next:before {
						content: '\e903';
					}
				}
			}
		}

		&.active {
			a {
				color: $clr-brand-dark-grey;
				background-color: $clr-brand-light-blue-1;
				cursor: default;
			}
		}

		&.disabled {
			cursor: default;
			pointer-events: none;

			i {
				color: $clr-brand-grey;
			}
		}

		&.disabled {
			cursor: default;
			pointer-events: none;

			i {
				color: $clr-brand-grey;
			}
		}

		&.disabled {
			cursor: default;
			pointer-events: none;

			i {
				color: $clr-brand-grey;
			}
		}
	}
}
</style>
