<template>
	<div>
		<component
			:is="activeComponent"
			:nachricht="nachricht"
			:vc="vc"
			:locale="locale"
		/>
	</div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useVCStore } from '@/stores/vc'
import { useI18n } from 'vue-i18n'

// imports

import not_found from './messageTypes/NotFoundType.vue'
// Auskunft-Templates
import supplement_regular from './messageTypes/Auskunft/SupplementRegular/index.vue'
import supplement_signal from './messageTypes/Auskunft/SupplementSignal/index.vue'
import supplement_cutoff_by_vc from './messageTypes/Auskunft/SupplementCutoffByVc/index.vue'
import actualisation from './messageTypes/Auskunft/Actualisation/index.vue'
import order_status_reply from './messageTypes/Auskunft/OrderStatusReply/index.vue'
import order_report_or_lack_of_report from './messageTypes/Auskunft/OrderReportOrLackOfReport/index.vue'

// Inkasso-Templates
import COLLECTION_PROCEDURE_STAGE_CHANGE from './messageTypes/Inkasso/CollectionProcedureStageChange/index.vue'
import COLLECTION_PROCEDURE_STARTED from './messageTypes/Inkasso/CollectionProcedureStarted/index.vue'
import DEFERMENT_AGREEMENT from './messageTypes/Inkasso/DefermentAgreement/index.vue'
import DEFERMENT_PAYMENT_IN_DEFAULT from './messageTypes/Inkasso/DefermentPaymentInDefault/index.vue'
import ENFORCEMENT_ORDER_DELIVERED from './messageTypes/Inkasso/EnforcementOrderDelivered/index.vue'
import ENFORCEMENT_ORDER_OBJECTION_RAISED from './messageTypes/Inkasso/EnforcementOrderObjectionRaised/index.vue'
import FIELD_SERVICE_ON_SITE_COLLECTION_STARTED from './messageTypes/Inkasso/FieldServiceOnSiteCollectionStarted/index.vue'
// import FIELD_SERVICE_ON_SITE_COLLECTION_COMPLETED from './messageTypes/Inkasso/FieldServiceOnSiteCollectionCompleted/index.vue'
import INSTALMENT_AGREEMENT from './messageTypes/Inkasso/InstalmentAgreement/index.vue'
import INSTALMENT_PAYMENT_IN_DEFAULT from './messageTypes/Inkasso/InstalmentPaymentInDefault/index.vue'
import LETTER_RETURNS from './messageTypes/Inkasso/LetterReturns/index.vue'
import NEGATIVE_FACT_DELETED_INSOLVENCY from './messageTypes/Inkasso/NegativeFactDeletedInsolvency/index.vue'
import NEGATIVE_FACT_RECORDED_ASSET_DISCLOSURE from './messageTypes/Inkasso/NegativeFactRecordedAssetDisclosure/index.vue'
import NEGATIVE_FACT_RECORDED_INSOLVENCY from './messageTypes/Inkasso/NegativeFactRecordedInsolvency/index.vue'
import OUTGOING_LETTER_ENFORCEMENT_PROCEDURE_STEP from './messageTypes/Inkasso/OutgoingLetterEnforcementProcedureStep/index.vue'
// import OUTGOING_LETTER_PROPOSAL_FOR_AGREEMENT from ./messageTypes/Inkasso/ReceiptOfPayment/index.vue'
import OUTGOING_LETTER_REQUEST from './messageTypes/Inkasso/OutgoingLetterRequest/index.vue'
import OUTGOING_LETTER_REQUEST_FOR_COMPLETION from './messageTypes/Inkasso/OutgoingLetterRequestForCompletion/index.vue'
import OUTGOING_LETTER_REQUEST_OF_DOCUMENTS from './messageTypes/Inkasso/OutgoingLetterRequestOfDocuments/index.vue'
import PAYMENT_ORDER_DELIVERED from './messageTypes/Inkasso/PaymentOrderDelivered/index.vue'
import PAYMENT_ORDER_OBJECTION_RAISED from './messageTypes/Inkasso/PaymentOrderObjectionRaised/index.vue'
import RECEIPT_OF_PAYMENT from './messageTypes/Inkasso/ReceiptOfPayment/index.vue'
import SETTLEMENT_AGREEMENT from './messageTypes/Inkasso/SettlementAgreement/index.vue'
import SETTLEMENT_PAYMENT_IN_DEFAULT from './messageTypes/Inkasso/SettlementPaymentInDefault/index.vue'
import {storeToRefs} from 'pinia'
import {TypisierteNachricht} from '@/models/Messages/TypisierteNachricht'

// imports

const componentList = [
	{
		name: 'not_found',
		component: not_found
	},
	{
		name: 'supplement_regular',
		component: supplement_regular
	},
	{
		name: 'supplement_signal',
		component: supplement_signal
	},
	{
		name: 'supplement_cutoff_by_vc',
		component: supplement_cutoff_by_vc
	},
	{
		name: 'actualisation',
		component: actualisation
	},
	{
		name: 'order_status_reply',
		component: order_status_reply
	},
	{
		name: 'order_report_or_lack_of_report',
		component: order_report_or_lack_of_report
	},
	{
		name: 'COLLECTION_PROCEDURE_STAGE_CHANGE',
		component: COLLECTION_PROCEDURE_STAGE_CHANGE
	},
	{
		name: 'COLLECTION_PROCEDURE_STARTED',
		component: COLLECTION_PROCEDURE_STARTED
	},
	{
		name: 'DEFERMENT_AGREEMENT',
		component: DEFERMENT_AGREEMENT
	},
	{
		name: 'DEFERMENT_PAYMENT_IN_DEFAULT',
		component: DEFERMENT_PAYMENT_IN_DEFAULT
	},
	{
		name: 'ENFORCEMENT_ORDER_DELIVERED',
		component: ENFORCEMENT_ORDER_DELIVERED
	},
	{
		name: 'ENFORCEMENT_ORDER_OBJECTION_RAISED',
		component: ENFORCEMENT_ORDER_OBJECTION_RAISED
	},
	{
		name: 'FIELD_SERVICE_ON_SITE_COLLECTION_STARTED',
		component: FIELD_SERVICE_ON_SITE_COLLECTION_STARTED
	},
	// {
	// 	name: 'FIELD_SERVICE_ON_SITE_COLLECTION_COMPLETED',
	// 	component: FIELD_SERVICE_ON_SITE_COLLECTION_COMPLETED
	// },
	{
		name: 'INSTALMENT_AGREEMENT',
		component: INSTALMENT_AGREEMENT
	},
	{
		name: 'INSTALMENT_PAYMENT_IN_DEFAULT',
		component: INSTALMENT_PAYMENT_IN_DEFAULT
	},
	{
		name: 'LETTER_RETURNS',
		component: LETTER_RETURNS
	},
	{
		name: 'NEGATIVE_FACT_DELETED_INSOLVENCY',
		component: NEGATIVE_FACT_DELETED_INSOLVENCY
	},
	{
		name: 'NEGATIVE_FACT_RECORDED_ASSET_DISCLOSURE',
		component: NEGATIVE_FACT_RECORDED_ASSET_DISCLOSURE
	},
	{
		name: 'NEGATIVE_FACT_RECORDED_INSOLVENCY',
		component: NEGATIVE_FACT_RECORDED_INSOLVENCY
	},
	{
		name: 'OUTGOING_LETTER_ENFORCEMENT_PROCEDURE_STEP',
		component: OUTGOING_LETTER_ENFORCEMENT_PROCEDURE_STEP
	},
	// {
	// 	name: 'OUTGOING_LETTER_PROPOSAL_FOR_AGREEMENT',
	// 	component: OUTGOING_LETTER_PROPOSAL_FOR_AGREEMENT
	// },
	{
		name: 'OUTGOING_LETTER_REQUEST',
		component: OUTGOING_LETTER_REQUEST
	},
	{
		name: 'OUTGOING_LETTER_REQUEST_FOR_COMPLETION',
		component: OUTGOING_LETTER_REQUEST_FOR_COMPLETION
	},
	{
		name: 'OUTGOING_LETTER_REQUEST_OF_DOCUMENTS',
		component: OUTGOING_LETTER_REQUEST_OF_DOCUMENTS
	},
	{
		name: 'PAYMENT_ORDER_DELIVERED',
		component: PAYMENT_ORDER_DELIVERED
	},
	{
		name: 'PAYMENT_ORDER_OBJECTION_RAISED',
		component: PAYMENT_ORDER_OBJECTION_RAISED
	},
	{
		name: 'RECEIPT_OF_PAYMENT',
		component: RECEIPT_OF_PAYMENT
	},
	{
		name: 'SETTLEMENT_AGREEMENT',
		component: SETTLEMENT_AGREEMENT
	},
	{
		name: 'SETTLEMENT_PAYMENT_IN_DEFAULT',
		component: SETTLEMENT_PAYMENT_IN_DEFAULT
	},
]

const activeComponent = computed(() => {
	const component = componentList.find((component) => component.name === props.template)
	return component ? component.component : componentList.find(component => component.name === 'not_found')?.component
})

const { vc } = storeToRefs(useVCStore())
const { locale } = useI18n()

const props = withDefaults(defineProps<{
	nachricht?: TypisierteNachricht,
	template?: string
}>(), {
    template: ''
})

</script>
