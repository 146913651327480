<template>
	<div>
		<component
			:is="components[locale as keyof ContainerOfAllLocales]"
			:template-vars="getNachricht"
		/>
	</div>
</template>

<script lang="ts" setup>
import { computed, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'

import de_DE from '../templates/de_DE/SupplementSignalTemplate.vue'
import de_AT from '../templates/de_DE/SupplementSignalTemplate.vue'
import en_US from '../templates/en_US/SupplementSignalTemplate.vue'
import fr_FR from '../templates/fr_FR/SupplementSignalTemplate.vue'
import fr_LU from '../templates/fr_FR/SupplementSignalTemplate.vue'
import {ContainerOfAllLocales} from '@/types/ContainerOfAllLocales'
import { Auskunft, getMergedReportData } from '@/models/Messages/Auskunft'

const components = shallowRef<ContainerOfAllLocales>({
	de_DE,
	de_AT,
	en_US,
	fr_FR,
	fr_LU,
})
const { locale, t } = useI18n()

const props = defineProps<{
	nachricht?: Auskunft
	vc?: { name: string }
}>()

const getNachricht = computed(() => {
	return {
		productOrderId: props.nachricht?.attributes?.productOrderId,
		mergedReportData: getMergedReportData(props.nachricht?.attributes, locale.value),
		signalReasonIdList:
			typeof props.nachricht?.attributes?.signalReasonIdList === 'object'
				? props.nachricht?.attributes?.signalReasonIdList
				: undefined,
		transactionLink: `${t('link.unternehmensdetailseite')}?no_cache=1&country=DE&businessId=${props.nachricht?.attributes?.businessId}`,
		vcName: props.vc?.name,
	}
})
</script>
