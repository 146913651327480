<template>
    <div>
        <p>
            Dear Sir or Madam,
        </p>

        <p>
            there is new information about the company {{ templateVars.mergedReportData }} in your Signal.
        </p>

        <!-- TODO: fix for prettier (ul in p allowed?)-->
        <p>
            <strong>The changes concern:</strong>
        <ul class="posteingang-inbox__message-details-messages__message__change-list">
            <li v-for="reason in templateVars.signalReasonIdList" :key="reason">
                {{ $t("signalReason." + reason) }}
            </li>
        </ul>
        </p>

        <p>
            You can receive current information about the company
            <crefo-message-link :msg-link="templateVars.transactionLink" :msg-text="'here'" />.
        </p>

        <p>With kind regards</p>

        <p>{{ templateVars.vcName }}</p>
    </div>
</template>

<script lang="ts">
import CrefoMessageLink from '@/components/atoms/CrefoMessageLink/index.vue'

export default {
    components: {
        CrefoMessageLink
    },
    props: {
        templateVars: {
            type: Object,
            default: null
        }
    }
}
</script>
