import countries from 'i18n-iso-countries'
import de from 'i18n-iso-countries/langs/de.json'
import en from 'i18n-iso-countries/langs/en.json'
import fr from 'i18n-iso-countries/langs/fr.json'
import type { Alpha2Code, Alpha3Code } from 'i18n-iso-countries'

countries.registerLocale(de)
countries.registerLocale(en)
countries.registerLocale(fr)

export default {
	getCountryName(code: string | number | Alpha2Code | Alpha3Code | undefined, lang: string) {
		if (code === undefined) {
			return undefined
		}
		const newLang = this.getLanguageKey(lang)
		return countries.getName(code, newLang, { select: 'official' })
	},

	getLanguageKey(lang: string) {
		let key = null
		switch (lang) {
			case 'en_US':
				key = 'en'
				break
			case 'fr_FR':
			case 'fr_LU':
				key = 'fr'
				break
			default:
				key = 'de'
				break
		}
		return key
	},
}
