<template>
	<div class="row">
		<div
			class="col-12"
			:class="{ selectActive: mobileSelected }"
		>
			<MessageTools
				v-if="gesamtAnzahlNachrichten"
			/>
			<div class="posteingang-inbox box-shadow-xy">
				<crefo-ring-loading
					v-if="isFetchingData"
					:wrap-in-row="false"
					additional-classes="flex center-xy"
				/>
				<div
					v-if="!isFetchingData"
					class="posteingang-inbox--content"
					data-qa="posteingang-inbox--content"
				>
					<div
						v-if="gesamtAnzahlNachrichten"
						class="row no-gutters"
					>
						<div
							class="posteingang-inbox__message-list col-12 col-md-6"
							data-qa="posteingang-message-list"
						>
							<MessageList
                  :nachrichtenliste="nachrichtenliste as Array<TypisierteNachricht>"
              />
						</div>
						<div class="posteingang-inbox__message-details col-12 col-md-6">
							<MessageDetails />
						</div>
					</div>
					<div
						v-else
						class="posteingang-placeholder"
					>
						<div class="col-10">
							<placeholder-image
								:show-message="true"
								:selected-members="selectedMembers"
								:member-structure-count="memberStructure.length"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="row no-gutters pt-small">
				<div class="col-12">
					<MessagePagination
						v-if="gesamtAnzahlNachrichten"
						:gesamt-anzahl-nachrichten="gesamtAnzahlNachrichten"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { watch, onMounted } from 'vue'

import { useMessagesStore } from '@/stores/messages'
import { useMemberStructureStore } from '@/stores/memberStructure'
import { useUserStore } from '@/stores/user'
import { useVCStore } from '@/stores/vc'
import MessageTools from '@/components/molecules/MessageTools/index.vue'
import MessageList from '@/components/molecules/MessageList/index.vue'
import MessageDetails from '@/components/molecules/MessageDetails/index.vue'
import MessagePagination from '@/components/molecules/MessagePagination/index.vue'
import CrefoRingLoading from '@dm/crefo-ring-loading'
import PlaceholderImage from '@/components/atoms/PlaceholderImage/index.vue'
import {storeToRefs} from 'pinia'
import {TypisierteNachricht} from '@/models/Messages/TypisierteNachricht'

const messagesStore = useMessagesStore()
const { gesamtAnzahlNachrichten, isFetchingData, mobileSelected, nachrichtenliste, pagination, selectedNachricht, sort } = storeToRefs(messagesStore)
const { memberStructure, selectedMembers } = storeToRefs(useMemberStructureStore())
const userStore = useUserStore()
const { authenticated } = storeToRefs(userStore)
const vcStore = useVCStore()

watch(
	() => pagination.value.pageSize,
	(newPageSize, oldPageSize) => {
		if (newPageSize !== oldPageSize) {
			fetchPosteingangFromStore()
		}
	},
)

watch(
	() => pagination.value.pageStartIndex,
	(newPageIndex, oldPageIndex) => {
		if (newPageIndex !== oldPageIndex) {
			fetchPosteingangFromStore()
		}
	},
)

watch(
	() => sort.value,
	(newSorting, oldSorting) => {
		if (newSorting !== oldSorting) {
			fetchPosteingangFromStore()
		}
	},
)

watch(
	() => selectedMembers.value,
	(newMembers, oldMembers) => {
		const listOfNewSelectedMemberIds = newMembers.map((item) => item.memberId)
		const listOfOldSelectedMemberIds = oldMembers.map((item) => item.memberId)
		if (listOfNewSelectedMemberIds.toString() !== listOfOldSelectedMemberIds.toString()) {
			fetchPosteingangFromStore()
		}
	},
)

onMounted(async () => {
	if (authenticated.value) {
		await fetchData()
	} else {
		userStore.$subscribe(async (mutation, state) => {
			if (state.authenticated) {
				await fetchData()
			}
		})
	}
})

async function fetchData() {
	messagesStore.readCookies()
	messagesStore
		.getSearchFilter()
		.then((response) => {
			messagesStore.setSavedSearchFilter(response.data)
		})

	// TODO:
	// todo-sk: Jan fragen, ob ein VC innerhalb einer Struktur unterschiedlich sein kann?
	await vcStore.getVC()
}

async function fetchPosteingangFromStore(unsetSelectedNachricht = true) {
    isFetchingData.value = true
	if (unsetSelectedNachricht) {
		selectedNachricht.value = undefined
	}
	await messagesStore.fetchPosteingang()
    isFetchingData.value = false
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars/colors';
@import '@/assets/scss/vars/breakpoints';
@import '@/assets/scss/mixins/media-breakpoints';

$posteingang-height: 1120px;

.selectActive {
	.posteingang-inbox {
		.posteingang-inbox__message-list {
			display: none;

			@include media-up(md) {
				display: block;
			}
		}
		.posteingang-inbox__message-details {
			display: flex;

			@include media-up(md) {
				display: block;
			}
		}
	}
}

.posteingang-inbox {
	height: $posteingang-height;
	.posteingang-inbox__message-list {
		overflow-x: auto;
		height: $posteingang-height;
	}

	.posteingang-inbox__message-details {
		height: $posteingang-height;
		display: none;
		border-left: none;
		@include media-up(md) {
			display: block;
			border-left: 1px solid $clr-brand-light-grey-2;
		}
	}

	.flex {
		display: flex;
	}
	.center-xy {
		justify-content: center;
		align-items: center;
		height: 100%;
		padding-top:25%;
	}
}

.posteingang-inbox--content {
	display: flex;
	height: 100%;
	flex-direction: column;
}

.posteingang-placeholder {
	display: flex;
	justify-content: center;
	padding-top: 150px;
	text-align: center;
}
</style>
