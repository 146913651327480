import { Base } from '@/models/Messages/Base'
import { Nachricht } from '@/types/messageBox/messages'
import { PostalAddress } from '@/models/Messages/Auskunft'

const AVAILABLE_TEMPLATES = [
	{ templateId: 'A004', template: 'supplement_signal' },
	{ templateId: 'LLFAR-4', template: 'supplement_signal' },
	{ templateId: 'A004-SignalEnde', template: 'supplement_cutoff_by_vc' },
	{ templateId: 'LLFAR-4-SignalEnde', template: 'supplement_cutoff_by_vc' },
]

type Attributes = {
	businessName?: string,
	businessId?: string,
	productOrderId?: string,
	postalAddress?: PostalAddress,
	deliveryTypeId?: string,
	referenceNumber?: string,
	signalReasonIdList?: string,
	statusReplyReasonId?: string,
	supplementReasonIdList?: string,
	transactionId?: string,
}
export class Signal extends Base {
	attributes: Attributes
	headlineText: string
	// type discriminator (same attribute name, different type and (constant) content):
	nachrichtType: "SIGNALE" = "SIGNALE"

	constructor(data: Nachricht) {
		super(data)
		this.attributes = {
			businessName: this.payload.displayName,
			businessId: this.payload.businessId,
			productOrderId: this.payload.orderId,
			postalAddress: this.getPostalAddress(this.payload),
			deliveryTypeId: this.payload.deliveryTypeId,
			referenceNumber: this.payload.referenceNumber,
			signalReasonIdList: this.payload.signalReasonIdList,
			statusReplyReasonId: this.payload.statusReplyReasonId,
			supplementReasonIdList: this.payload.supplementReasonIdList,
			transactionId: this.getTransactionId(this.payload),
		} as Attributes
		this.msgTemplate = this.getTemplate(data.templateId, AVAILABLE_TEMPLATES)
		this.headlineText = this.getHeadlineText(this.payload)
	}

	getPostalAddress(payload: { postalAdress?: PostalAddress }) {
		return {
			city: payload.postalAdress?.city,
			street: payload.postalAdress?.street,
			zipCode: payload.postalAdress?.zipCode,
			country: payload.postalAdress?.country,
			district: payload.postalAdress?.district,
			houseNumber: payload.postalAdress?.houseNumber,
			houseNumberSuffix: payload.postalAdress?.houseNumberSuffix,
			postOfficeBoxNumber: payload.postalAdress?.postOfficeBoxNumber,
			type: payload.postalAdress?.type
		} as PostalAddress
	}
}
