<template>
	<span
		class="crefoDate"
		data-qa="crefoDate"
		>{{ dateFormatted }}</span
	>
</template>

<script lang="ts" setup>
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import { useUserStore } from '@/stores/user'
import { computed } from 'vue'
import {storeToRefs} from 'pinia'

const { dateLocale } = storeToRefs(useUserStore())

const props = defineProps({
	date: {
		type: String,
		default: null,
	},
})

const dateFormatted = computed(() => {
	dayjs.extend(localizedFormat)
	return props.date
		? dayjs(props.date)
				.locale(dateLocale.value.substring(0, 2) || 'de')
				.format('L')
		: null
})
</script>
