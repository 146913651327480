export function dispatchEvent(eventName: string, bubbles = false, cancelable = false): Event | null {
	let event: Event | null = null

	if (typeof Event === 'function') {
		event = new Event(eventName, { bubbles, cancelable })
		window.dispatchEvent(event)
	} else if (typeof document.createEvent === 'function' && typeof window.Event !== 'function') {
		event = document.createEvent('Event')
		event.initEvent(eventName, bubbles, cancelable)
		window.dispatchEvent(event)
	}

	return event
}
