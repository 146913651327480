<template>
	<div
		ref="tooltip"
		class="ccl-tooltip"
	>
		<slot />
		<div
			id="arrow"
			class="ccl-tooltip__arrow"
			data-popper-arrow
		/>
	</div>
</template>

<script setup lang="ts">
import { createPopper } from '@popperjs/core'
import { onMounted, ref, watch, watchEffect, computed } from 'vue'

const props = defineProps({
	config: null,
	ankerElement: null,
})

const popperInstance = ref()
const tooltip = ref()

onMounted(() => {
	initPopper()
})

const popperConfig = computed(() => {
	return {
		placement: props?.config?.placement || 'top',
		modifiers: [
			{
				name: 'offset',
				options: {
					//@ts-ignore
					offset: ({ placement }) => {
						// { placement, reference, popper }
						const cornerPlacements = [
							'right-start',
							'left-start',
							'right-end',
							'left-end',
							'top-start',
							'bottom-start',
							'top-end',
							'bottom-end',
						]
						if (cornerPlacements.find((item: string) => item === placement)) {
							return [0, 20]
						} else {
							return [0, 10]
						}
					},
				},
			},
			...(props.config?.modifiers || []),
		],
		...props.config,
	}
})

function initPopper() {
	if (!props.ankerElement) return
	popperInstance.value = createPopper(props.ankerElement, tooltip.value, popperConfig.value)
}

watchEffect(() => {
	if (popperInstance.value) {
		popperInstance.value.destroy()
	}
	initPopper()
})

watch(props, async () => {
	await popperInstance.value.update()
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars/colors';

$color-tooltip-bg: #ffffff;
$color-tooltip-shadow: rgba(0, 0, 0, 0.15);

.ccl-tooltip {
	padding: 15px;
	color: #7f7f7f;
	background-color: $color-tooltip-bg;
	box-shadow: 0 0 14px $color-tooltip-shadow;
	font-size: 12px;
	min-width: 120px;
	min-height: 20px;
	text-align: center;
	z-index: 6;

	&__icon {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		color: gray;
		cursor: pointer;
		z-index: 1;
		height: 15px;
		width: 15px;
	}

	/* &__arrow,
    &__arrow::before {
        position: absolute;
        width: 10px;
        height: 10px;
        background: inherit;
    } */

	&__arrow {
		position: absolute;
		visibility: hidden;
	}

	&__arrow::before {
		display: block;
		visibility: visible;
		content: '';
		width: 0;
		height: 0;
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		border-top: 20px solid $color-tooltip-bg;
		transform: rotate(0);
	}

	&[data-popper-placement^='top'] .ccl-tooltip__arrow {
		bottom: -10px;
	}

	&[data-popper-placement='top'] .ccl-tooltip__arrow::before {
		transform: rotate(0deg);
	}

	&[data-popper-placement^='top-start'] .ccl-tooltip__arrow::before {
		transform: rotate(270deg);
	}

	&[data-popper-placement^='top-end'] .ccl-tooltip__arrow::before {
		transform: rotate(90deg);
	}

	&[data-popper-placement^='bottom'] .ccl-tooltip__arrow {
		top: -10px;
	}

	&[data-popper-placement='bottom'] .ccl-tooltip__arrow::before {
		transform: rotate(180deg);
	}

	&[data-popper-placement^='bottom-start'] .ccl-tooltip__arrow::before {
		transform: rotate(270deg);
	}

	&[data-popper-placement^='bottom-end'] .ccl-tooltip__arrow::before {
		transform: rotate(90deg);
	}

	&[data-popper-placement^='left'] .ccl-tooltip__arrow {
		right: -20px;
	}

	&[data-popper-placement='left'] .ccl-tooltip__arrow::before {
		transform: rotate(270deg);
	}

	&[data-popper-placement^='left-start'] .ccl-tooltip__arrow::before {
		transform: rotate(180deg);
	}

	&[data-popper-placement^='right-start'] .ccl-tooltip__arrow::before {
		transform: rotate(180deg);
	}

	&[data-popper-placement^='right'] .ccl-tooltip__arrow {
		left: -20px;
	}

	&[data-popper-placement='right'] .ccl-tooltip__arrow::before {
		transform: rotate(90deg);
	}
}
</style>
