<template>
	<div>
		<h1
			v-if="showMessage && !isLoading"
			data-qa="placeholder-image-message"
		>
			{{ getMessage }}
		</h1>
		<img
			data-qa="placeholder-image-src"
			src="@/assets/images/platzhalter-posteingang.svg"
			:width="imageWidth"
			alt="Placeholder Image"
		/>
	</div>
</template>

<script lang="ts" setup>
import { useMessagesStore } from '@/stores/messages'
import { useMemberStructureStore } from '@/stores/memberStructure'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import {storeToRefs} from 'pinia'

const { t } = useI18n()
const { isFetchingData: messagesStoreIsFetching } = storeToRefs(useMessagesStore())
const { isFetchingData: memberStructureIsFetching } = storeToRefs(useMemberStructureStore())

const props = withDefaults(defineProps<{
	selectedMembers?: Array<any>
	showMessage?: boolean
	imageWidth?: string
	memberStructureCount?: number
}>(), {
    selectedMembers: () => [],
    showMessage: false,
    imageWidth: '300px',
    memberStructureCount: 0
})

const isLoading = computed(() => {
	return messagesStoreIsFetching.value || memberStructureIsFetching.value
})

const getMessage = computed(() => {
	let ret = t('Posteingang.Inbox.No.Messages')
	if (props.memberStructureCount > 1 && !(props.selectedMembers.length)) {
		ret = t('Posteingang.Inbox.No.Member.Selected')
	}
	return ret
})
</script>
