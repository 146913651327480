import {
    createRouter,
    createWebHistory,
    NavigationGuard,
    RouteLocationNormalized,
    RouteRecordRaw,
	NavigationGuardNext
} from 'vue-router'

import { dispatchEvent } from '@/helper/polyfill'
import { useUserStore } from '@/stores/user'

import AccessNotAllowed from '@/views/AccessNotAllowed.vue'
import Postbox from '@/views/Postbox/index.vue'

// current valid values are: "/portal" & "/backoffice"
const BASE_PATH_MICROFRONTEND_CONTAINER = `/${import.meta.env.VITE_APP_BASE_PATH}`
const PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP = import.meta.env.VITE_APP_PUBLIC_ENTRY_ROUTE
const timeToWait = 2000

// Called in "beforeEnter" on protected routes
function isAuthenticated(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
	return isAuthenticatedInner(to, from, next)
}

const isAuthenticatedInner = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext, timeout = 100, timeElapsed = 0): ReturnType<NavigationGuard> => {
	const userStore = useUserStore()
	if (userStore.authenticated && window?.keycloak?.token) {
		next()
	} else if (timeElapsed >= timeToWait && window.keycloak && window.keycloak.token) {
		userStore.onValidTokenExists(window.keycloak)
    	userStore.getCbraUser()
		next()
	} else if (timeElapsed >= timeToWait) {
		console.log('Logger -> redirectToLogin');
		
		dispatchEvent('redirectToLogin')
	} else {
		timeout = Math.max(Math.min(timeout, timeToWait - timeElapsed), 1)
		setTimeout(() => {
			isAuthenticatedInner(to, from, next, timeout * 2, timeElapsed + timeout)
		}, timeout)
	}
}

export const routes: RouteRecordRaw[] = [
	{
		path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}`,
		name: 'index',
		component: Postbox,
		beforeEnter: isAuthenticated,
	},
	{
		path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/zugriff-verweigert`,
		name: 'access-not-allowed',
		component: AccessNotAllowed,
		beforeEnter: isAuthenticated,
	},
]

const router = createRouter({
	history: createWebHistory('/'),
	routes,
	scrollBehavior: (to): void => {
		if (!to.hash) {
			window.scroll(0, 0)
		}
	},
})

router.beforeEach(() => {
	// Fix for Vue Router 3.6.5 from backoffice-base-layout
	// TODO: Check compatibility of different Vue versions with different Vue Router versions

	// Vue 3 and Vue Router 4.2.2 is pushing something like this to the history state on route change:
	// {
	//     "back": "/backoffice/listenverarbeitung/neue-verarbeitung#state=c0c7192b-4701-425b-84b5-c09939a6e81e&session_state=b9c5005e-f70b-4606-8eee-cb83390537d6&code=0b14baf2-b7ca-4b17-ad18-135b934894fc.b9c5005e-f70b-4606-8eee-cb83390537d6.1d9e6179-1511-45f5-bb69-87151e25f784",
	//     "current": "/backoffice/listenverarbeitung/uebersicht",
	//     "forward": null,
	//     "replaced": false,
	//     "position": 40,
	//     "scroll": null
	// }

	// Vue 2 and Vue Router 3.6.5 is pushing something like this to the history state on route change:
	// {
	//     "key": "108468.700"
	// }

	// could be produce sideeffects with scrolling in Vue Router 3.6.5
	// https://github.com/vuejs/vue-router/blob/e53a549f04e9e02d293d8a4c2537a046ae2381dc/src/util/scroll.js#L23

	if (window.history.state?.key) {
		window.history.replaceState(null, '')
	}
})

export default router
