<template>
	<div>
		<p>Mesdames et Messieurs,</p>

		<p>
			La mise à jour du rapport {{ templateVars.productOrderId }} sur l'entreprise
			{{ templateVars.mergedReportData }}
			est disponible.
		</p>

		<p>
			Veuillez cliquer
			<crefo-message-link
				:msg-link="templateVars.transactionLink"
				:msg-text="'ici'"
			/>
			pour afficher le rapport actualisé.
		</p>

		<p>Cordialement</p>

		<p>Votre {{ templateVars.vcName }}</p>
	</div>
</template>

<script lang="ts">
import CrefoMessageLink from '@/components/atoms/CrefoMessageLink/index.vue'

export default {
	components: {
		CrefoMessageLink,
	},
	props: {
		templateVars: {
			type: Object,
			default: null,
		},
	},
}
</script>
