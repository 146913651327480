import axios from './axios-tracing'
import { AxiosResponse } from 'axios'
import applyMockAdapter from './index-mock'
import type {NachrichtKategorie} from '@/types/messageBox/MessageSearch'
import { PosteingangSearchRequest, PosteingangSaveSearchRequest } from '@/types/messageBox/MessageSearch'
import type {MemberStructure, MemberStructurePreferences} from '@/types/memberStructure/memberStructure'
import { AxiosPromise } from 'axios'
import type { Vc } from '@/types/stores/vc'
import {Nachrichtenliste} from '@/types/messageBox/messages'


if (import.meta.env.MODE === 'local-only' || import.meta.env.MODE === 'test') {
	applyMockAdapter(axios)
}

// Set default headers to prevent caching
// @ts-ignore
axios.defaults.headers = {
	'Cache-Control': 'no-cache',
	Pragma: 'no-cache',
	Expires: '0',
}

const API_PATH = '/api/posteingang'

export const getMemberStructure = async (id: string) => {
	try {
		return (await axios.get<MemberStructure, AxiosResponse<MemberStructure>, void>(`${API_PATH}/mitgliedsstruktur/${id}`)).data
	} catch (error) {
		console.log('Logger -> FILE: index.js -> LINE: 21 -> getMemberStructure -> error:', error)
	}
}

export const saveSelectedMembers = async (params: MemberStructurePreferences) => {
	try {
		return (await axios.post(`${API_PATH}/userpreferences/mitgliedsstrukturpreferences`, params)).data
	} catch (error) {
		console.log('Logger -> FILE: index.js -> LINE: 22 -> getSelectedMembers -> error:', error)
	}
}

export const getSelectedMembers = () => {
		return axios.get<MemberStructurePreferences, AxiosResponse<MemberStructurePreferences>, void>(`${API_PATH}/userpreferences/mitgliedsstrukturpreferences`)
			.then(reponse => reponse.data)
			.catch(error => {
				console.log('Logger -> FILE: index.js -> LINE: 97 -> getSelectedMembers -> error', error)
				return error
			})
}

export const getMessageUnreadCountAll = async (id: string) => {
	try {
		return (await axios.get(`${API_PATH}/mitgliedsstruktur/unreadCount/${id}`)).data
	} catch (error) {
		console.log('Logger -> FILE: index.js -> LINE: 123 -> getMessageUnreadCountAll -> error:', error)
	}
}

export const fetchPosteingang = (params: PosteingangSearchRequest) => axios.post<Nachrichtenliste, AxiosResponse<Nachrichtenliste>, PosteingangSearchRequest>(`${API_PATH}/nachricht/search`, params)
export const markSelectedNachrichtenAsRead = (params: string[]): AxiosPromise => axios.post(`${API_PATH}/nachricht/markRead`, params)
export const markSelectedNachrichtenAsUnread = (params: string[]): AxiosPromise => axios.post(`${API_PATH}/nachricht/markUnRead`, params)
export const markNachrichtAsDeleted = (params: string[]): AxiosPromise => axios.post(`${API_PATH}/nachricht/markDeleted`, params)
export const saveNachrichtKategorie = (params: NachrichtKategorie): AxiosPromise => axios.post(`${API_PATH}/nachricht/setKategorie`, params)
export const getVC = (): AxiosPromise => axios.get<Vc, AxiosResponse<Vc>, void>('/cbra/vc/forUser')
export const getSearchFilter = () => axios.get<any, AxiosResponse<any>, void>(`${API_PATH}/userpreferences`)
export const saveSearchFilter = (value: PosteingangSaveSearchRequest): AxiosPromise => axios.post(`${API_PATH}/userpreferences`, value)
