<template>
	<div>
		<component
			:is="components[locale as keyof ContainerOfAllLocales]"
			:template-vars="getNachricht"
		/>
	</div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, shallowRef } from 'vue'

import de_DE from '../templates/de_DE/SupplementCutoffByVcTemplate.vue'
import de_AT from '../templates/de_DE/SupplementCutoffByVcTemplate.vue'
import en_US from '../templates/en_US/SupplementCutoffByVcTemplate.vue'
import fr_FR from '../templates/fr_FR/SupplementCutoffByVcTemplate.vue'
import fr_LU from '../templates/fr_FR/SupplementCutoffByVcTemplate.vue'
import {ContainerOfAllLocales} from '@/types/ContainerOfAllLocales'
import { Auskunft, getMergedReportData } from '@/models/Messages/Auskunft'

const { locale, t } = useI18n()

const components = shallowRef<ContainerOfAllLocales>({
	de_DE,
	de_AT,
	en_US,
	fr_FR,
	fr_LU,
})
const props = defineProps<{
	nachricht?: Auskunft
	vc?: { name: string }
}>()

const getNachricht = computed(() => {
	return {
		productOrderId: props.nachricht?.attributes?.productOrderId,
		mergedReportData: getMergedReportData(props.nachricht?.attributes, locale.value),
		signalReasonIdList:
			typeof props.nachricht?.attributes?.signalReasonIdList === 'object'
				? props.nachricht?.attributes?.signalReasonIdList
				: null,
		transactionLink: `${t('link.vorgangsdetailseite')}?no_cache=1&transactionId=${props.nachricht?.attributes?.transactionId}`,
		vcName: props.vc?.name,
	}
})
</script>
