<template>
	<div>
		<div
			class="row no-gutters posteingang-messageTools mb-small"
			data-qa="posteingang-messageTools"
		>
			<div class="posteingang-messageTools-tools col-12 col-md-6">
				<div class="posteingang-messageTools-tools__selectFilter">
					<div class="crefo-input">
						<div class="input-group">
							<div class="checkbox-group">
								<div class="input-wrapper">
									<div class="crefo-checkbox">
										<input
											id="toggleAll"
											v-model="toggleAll"
											type="checkbox"
											data-qa="posteingang-messageTools-tools--selectAll"
											@click="selectAllMessages"
										/>
										<label for="toggleAll" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="posteingang-messageTools-tools__delete-sort">
					<div class="trash">
						<font-awesome-icon
							icon="fa-light fa-envelope-open"
							:class="{ active: unreadMessagesSelected }"
							data-qa="posteingang-messageTools-tools--unread-selected-messages"
							:title="t('Posteingang.MessageTools.Button.Unread.Alt.Title')"
							@click="markMessagesAsRead"
						/>
					</div>
					<div class="trash makeUnread">
						<font-awesome-icon
							icon="fa-light fa-envelope"
							:class="{ active: readMessagesSelected }"
							data-qa="posteingang-messageTools-tools--read-selected-messages"
							:title="t('Posteingang.MessageTools.Button.Read.Alt.Title')"
							@click="markMessagesAsUnread"
						/>
					</div>
					<div class="trash">
						<font-awesome-icon
							icon="fa-light fa-trash-can"
							:class="{ active: messagesSelected }"
							data-qa="posteingang-messageTools-tools--delete-selected-messages"
							:title="t('Posteingang.MessageTools.Button.Delete.Alt.Title')"
							@click="showDeleteConfirmationDialog"
						/>
					</div>
					<!-- Sortieren -->
					<div
						v-click-outside="hideSorting"
						class="sorting"
					>
						<span
							class="sorting-link"
							data-qa="posteingang-messageTools-tools--sorting"
							@click.stop="showSorting"
							>{{ t('sortieren') }}

							<font-awesome-icon :icon="['far', sortingVisible ? 'angle-up' : 'angle-down']" />
						</span>
						<div
							v-if="sortingVisible"
							class="sorting-area"
						>
							<ul>
								<li
									:class="{ active: sort === 'CREATION_TIME_DESC' }"
									@click="setSorting('CREATION_TIME_DESC')"
								>
									{{ t('sorting.CREATION_TIME_DESC') }}
								</li>
								<li
									:class="{ active: sort === 'CREATION_TIME_ASC' }"
									@click="setSorting('CREATION_TIME_ASC')"
								>
									{{ t('sorting.CREATION_TIME_ASC') }}
								</li>
							</ul>
						</div>
					</div>
					<!-- Anzahl -->
					<div
						v-if="pagination.pageSize"
						v-click-outside="hideNumberRecords"
						class="number-records"
					>
						<span
							class="number-records-link"
							data-qa="posteingang-messageTools-tools--number-records"
							@click.stop="showNumberRecords"
							>{{ t('anzahl') }}
							<font-awesome-icon :icon="['far', numberRecordsVisible ? 'angle-up' : 'angle-down']" />
						</span>
						<div
							v-if="numberRecordsVisible"
							class="number-records-area"
						>
							<ul>
								<li
									v-for="(possiblePageSize, index) in numberRecords"
									:key="index"
									:class="{ active: possiblePageSize === pagination.pageSize }"
									@click="setNumberRecords(possiblePageSize)"
								>
									{{ possiblePageSize }}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="posteingang-messageTools-tools col-12 col-md-6">
				<MessagePagination :gesamt-anzahl-nachrichten="gesamtAnzahlNachrichten" />
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMessagesStore } from '@/stores/messages'
import MessagePagination from '@/components/molecules/MessagePagination/index.vue'
import {storeToRefs} from 'pinia'
import {PageSizes, Sortings} from '@/types/messageBox/MessageSearch'

const sortingVisible = ref(false)
const numberRecordsVisible = ref(false)
const numberRecords = ref<PageSizes[]>([10, 25, 50, 100, 200])

const messagesStore = useMessagesStore()
const { gesamtAnzahlNachrichten, multiSelectedMessages, nachrichtenliste, pagination, selectedNachricht, sort } = storeToRefs(messagesStore)
const { t } = useI18n()

const toggleAll = computed(() => multiSelectedMessages.value.length === nachrichtenliste.value.length)

const messagesSelected = computed(() => multiSelectedMessages.value.length > 0 || false)

const readMessagesSelected = computed(() => {
	const countReadSelected = multiSelectedMessages.value.filter(item => item.isRead).length
	return countReadSelected > 0 && multiSelectedMessages.value.length > 0
})

const unreadMessagesSelected = computed(() => {
	const countUnreadSelected = multiSelectedMessages.value.filter(item => !item.isRead).length
	return countUnreadSelected > 0 && multiSelectedMessages.value.length > 0
})

function setNumberRecords(numberRecord: PageSizes) {
	messagesStore.setNewPageSize(numberRecord)
	hideNumberRecords()
}

function markMessagesAsRead() {
	let allMessagesToMakeRead = JSON.parse(JSON.stringify(multiSelectedMessages.value))
	messagesStore.markSelectedMessagesAsRead(allMessagesToMakeRead)
	selectedNachricht.value = undefined
}

function markMessagesAsUnread() {
	let allMessagesToMakeUnread = JSON.parse(JSON.stringify(multiSelectedMessages.value))
	messagesStore.markSelectedMessagesAsUnread(allMessagesToMakeUnread)
	selectedNachricht.value = undefined
}

function setSorting(sorting: Sortings) {
	messagesStore.setNewSorting(sorting)
	sortingVisible.value = false
}

function showSorting() {
	sortingVisible.value = !sortingVisible.value
	numberRecordsVisible.value = false
}

function hideSorting() {
	sortingVisible.value = false
}

function showNumberRecords() {
	numberRecordsVisible.value = !numberRecordsVisible.value
	sortingVisible.value = false
}

function hideNumberRecords() {
	numberRecordsVisible.value = false
}

function selectAllMessages() {
	const localSelectedMessages: typeof multiSelectedMessages.value = []
	if (!toggleAll.value) {
      nachrichtenliste.value.forEach(nachricht => {
          localSelectedMessages.push(nachricht)
      })
	}

	multiSelectedMessages.value = localSelectedMessages
}

async function deleteSelectedMessages() {
	let allMessagesToDelete = JSON.parse(JSON.stringify(multiSelectedMessages.value))
	await messagesStore.deleteMessages(allMessagesToDelete)
}

function showDeleteConfirmationDialog() {
	if (multiSelectedMessages.value.length > 0) {
		window.CrefoUI.Modal.show({
			title: t('Posteingang.MessageTools.ShowDeleteConfirmation.Title'),
			content: t('Posteingang.MessageTools.ShowDeleteConfirmation.Content'),
			buttons: {
				confirm: t('Posteingang.MessageTools.ShowDeleteConfirmation.Confirm'),
				cancel: t('Posteingang.MessageTools.ShowDeleteConfirmation.Cancel'),
			},
			confirmCallback: () => {
				deleteSelectedMessages()
			},
			cancelCallback: () => {},
			icon: 'info',
			dataQa: 'posteingang-messageTools-tools-confirmation-dialog',
		})
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars/vars';
@import '@/assets/scss/mixins/media-breakpoints';
@import '@/assets/scss/functions/px2rem';
@import '@/assets/scss/vars/spacings';
.posteingang-messageTools-tools {
	padding: 0 20px;
	display: flex;
	justify-content: space-between;
	color: $clr-brand-blue;

	&__selectFilter {
		display: flex;
	}

	.crefo-input {
		display: flex;
		width: 15px;
		height: 15px;

		.input-group {
			margin-top: 4px;
		}
	}

	.crefo-input [type='checkbox']:checked + label,
	.crefo-input [type='checkbox']:not(:checked) + label {
		padding-left: 25px;
		line-height: 30px;
		cursor: pointer;
	}

	.crefo-input [type='checkbox']:checked + label:before,
	.crefo-input [type='checkbox']:not(:checked) + label:before {
		width: 15px;
		height: 15px;
	}

	.crefo-input [type='checkbox']:checked + label:after,
	.crefo-input [type='checkbox']:not(:checked) + label:after {
		top: 2px;
		left: 2px;
		font-size: 0.8rem;
		line-height: 0.8rem;
	}

	.crefo-checkbox {
		display: inherit;
		margin-right: 0;
	}

	.input-group-dropdown {
		display: flex;
		font-size: px2rem(24);
		line-height: px2rem(24);
		color: $clr-brand-blue;
	}

	&__delete-sort {
		display: flex;

		.unread {
			font-size: px2rem(20);
			cursor: default;
			color: $clr-brand-dark-grey;

			.active {
				cursor: pointer;
				color: $clr-brand-blue;
			}
		}

		.trash {
			font-size: px2rem(20);
			cursor: default;
			color: $clr-brand-dark-grey;
			margin-left: 20px;

			@include media-up(md) {
				margin-left: $tablet-normal-spacing;
			}
			@include media-up(lg) {
				margin-left: $desktop-normal-spacing;
			}

			&.makeUnread {
				padding-top: 2px;
			}

			.active {
				cursor: pointer;
				color: $clr-brand-blue;
			}
		}

		.sorting,
		.number-records {
			position: relative;
			margin-left: 20px;
			display: flex;

			@include media-up(md) {
				margin-left: $tablet-normal-spacing;
			}
			@include media-up(lg) {
				margin-left: $desktop-normal-spacing;
			}

			.sorting-link,
			.number-records-link {
				display: inline-flex;
				align-items: center;
				cursor: pointer;
			}
			svg {
				font-size: px2rem(16);
				margin-left: 5px;
				margin-top: 2px;
			}
			&-area {
				position: absolute;
				top: 100%;
				left: 0;
				background: $white;
				border: 1px solid $clr-brand-grey;
				z-index: 1;
				padding: 0;

				ul {
					list-style: none;
					padding: 0;
					margin: 0;
					display: flex;
					justify-content: flex-start;
					flex-direction: column;
					white-space: nowrap;

					li {
						padding: 0;
						margin: 0;
						cursor: pointer;
						padding: 10px;
						display: block;
						color: $clr-brand-blue;
						&.active {
							cursor: default;
							color: $clr-brand-dark-grey;
							background-color: $clr-brand-light-blue-1;
							font-weight: bold;
						}
						&:hover {
							color: $white;
							background-color: $clr-brand-blue;
						}
					}
				}
			}
		}

		.number-records {
			margin-right: 7px;
			@include media-up(md) {
				margin-right: 0;
			}
		}
	}
}
</style>
