<template>
	<div>
		<p>Sehr geehrte Damen und Herren,</p>

		<p>
			eine aktualisierte Auskunft zu Ihrem Produktauftrag {{ templateVars.productOrderId }} über das Unternehmen
			{{ templateVars.mergedReportData }} liegt jetzt vor.
		</p>

		<p>
			Bitte wechseln Sie hier zur
			<crefo-message-link
				:msg-link="templateVars.transactionLink"
				:msg-text="'Anzeige der Auskunft'"
			/>.
		</p>

		<p>Mit freundlichen Grüßen</p>

		<p>{{ templateVars.vcName }}</p>
	</div>
</template>

<script lang="ts">
import CrefoMessageLink from '@/components/atoms/CrefoMessageLink/index.vue'

export default {
	components: {
		CrefoMessageLink,
	},
	props: {
		templateVars: {
			type: Object,
			default: null,
		},
	},
}
</script>
