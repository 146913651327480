<template>
	<div class="row">
		<div class="col-12">
			<div
				class="posteingang-tabs"
				data-qa="posteingang-tabs"
			>
				<div class="tabs-wrapper">
					<span class="tab active">
						<font-awesome-icon icon="fa-light fa-inbox" />{{ t('posteingang') }}
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars/vars';
@import '@/assets/scss/mixins/media-breakpoints';
@import '@/assets/scss/functions/functions';

.posteingang-tabs {
	position: relative;
	margin-bottom: $normal-spacing;
	background: $white;

	@include media-up(md) {
		margin-bottom: $tablet-normal-spacing;
	}

	@include media-up(xl) {
		margin-bottom: $desktop-normal-spacing;
	}

	.tab,
	.tabs-nav {
		display: block;
		white-space: nowrap;
		margin-right: 5px;
		background: $white;
		cursor: pointer;
		color: $clr-brand-blue;
		font-family: 'Averta Std', 'Open Sans', Arial, Helvetica, sans-serif;
		font-weight: 600;
		transition: color 0.3s ease-in-out;
		position: relative;
		letter-spacing: px2rem(0.5);
		font-size: px2rem(20);
		line-height: px2rem(26);

		&:last-of-type {
			margin-right: 0;
		}

		&:hover {
			color: $clr-brand-darkest-blue;
		}
	}

	.tabs-wrapper {
		position: relative;
		overflow-x: auto;
		overflow-y: hidden;
		display: flex;
		border-bottom: 1px solid $clr-brand-light-grey-1;

		&.tab-nav-active {
			padding: 0 24.5px;
			margin-right: 24.5px;
		}

		.tab {
			padding: 15px 0;
			-webkit-touch-callout: none;
			/* iOS Safari */
			-webkit-user-select: none;
			/* Safari */
			-khtml-user-select: none;
			/* Konqueror HTML */
			-moz-user-select: none;
			/* Firefox */
			-ms-user-select: none;
			/* Internet Explorer/Edge */
			user-select: none;
			/* Non-prefixed version, currently
                                    supported by Chrome and Opera */
			margin-right: 60px;

			&:before {
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				right: 0;
				left: 0;
				height: 4px;
				width: auto;
				background-color: $clr-brand-blue;
				opacity: 0;
				transition: opacity 0.3s;
			}

			&.active {
				color: $clr-brand-darkest-blue;

				&:before {
					opacity: 1;
				}
			}

			.fa-inbox {
				margin-right: 15px;
			}
		}
	}

	.tabs-nav {
		display: none;
		position: absolute;
		z-index: 3;
		top: 0;
		border-bottom: 1px solid $clr-brand-light-grey-1;
		padding: 17px 2px 12px 3px;

		&.prev {
			left: 0;
			border-right: 1px solid $clr-brand-light-grey-1;
		}

		&.next {
			right: 0;
			border-left: 1px solid $clr-brand-light-grey-1;
		}

		&.active {
			display: block;
		}

		i {
			font-size: 18px;
		}
	}
}
</style>
