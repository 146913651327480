<template>
	<div>
		<p>Sehr geehrte Damen und Herren,</p>

		<p>
			der Nachrichtentyp konnte nicht ermittelt werden. Weitere Informationen entnehmen Sie bitte dem
			nachfolgenden Datensatz.
		</p>
		<p>Wir bitten dies zu entschuldigen.</p>
		<pre>{{ getJson }}</pre>

		<p>Mit freundlichen Grüßen,</p>
		<p>Ihr Team Unternehmensmonitoring</p>
	</div>
</template>

<script lang="ts">
export default {
	name: 'NotFoundType',
	props: {
		nachricht: {
			type: Object,
			default: null,
		},
	},
	computed: {
		getJson() {
			return JSON.stringify(this.nachricht, null, 2)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars/colors';

pre {
	display: block;
	font-family: monospace;
	white-space: pre;
	margin: 1em 0;
	height: 500px;
	overflow: auto;
	border: 1px solid $clr-brand-light-grey-2;
	background-color: $clr-brand-light-blue-2;
}
</style>
