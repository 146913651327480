import {CtiAddress, TransactionPayload} from '@/models/Messages/Inkasso'
interface Keycloak {
    idTokenParsed?: Object
    token?: string
}
export function hasKeycloakProperty(object: unknown): object is {
    keycloak: Keycloak
    updateKeycloakToken: () => Promise<void>
} {
    return Object.prototype.hasOwnProperty.call(object, 'keycloak')
}
export function hasCrefoUIProperty(object: unknown): object is { CrefoUI: any } {
    return Object.prototype.hasOwnProperty.call(object, 'CrefoUI')
}

export function isTransactionPayload(object: unknown): object is TransactionPayload {
    return Object.prototype.hasOwnProperty.call(object, 'transactionId') && (object as { transactionId: number }).transactionId !== -1
}

export function isCtiAddress(object: unknown): object is CtiAddress {
    return Object.prototype.hasOwnProperty.call(object, 'houseNumber')
        || Object.prototype.hasOwnProperty.call(object, 'houseNumberSuffix')
}
