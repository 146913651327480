import {MemberStructureMember, MemberStructureMemberFlat} from '@/types/memberStructure/memberStructure'

export abstract class BaseMember implements MemberStructureMemberFlat {
	public memberId: string
	public memberName: string
	public address: string
	public countUnread: number
	public isMainMember: boolean;

	protected constructor(data: MemberStructureMember, isMainMember: boolean) {
		this.memberId = data.memberId || ''
		this.memberName = data.memberName || ''
		this.address = data.address_line2 || ''
		this.countUnread = data.countUnread
		this.isMainMember = isMainMember
	}
}
