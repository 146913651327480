import { Base } from '@/models/Messages/Base'
import { Nachricht } from '@/types/messageBox/messages'
import { isCtiAddress, isTransactionPayload } from '@/types/InterfaceGuards'
import { InkassoNachricht } from '@/generated'

const Participant = {
	CREDITREFORM: 'CREDITREFORM',
	CREDITREFORM_AIA: 'CREDITREFORM_AIA',
	DEBTOR: 'DEBTOR',
	CREDITOR: 'CREDITOR',
	ATTORNEY_OF_CREDITOR: 'ATTORNEY_OF_CREDITOR',
	ATTORNEY_OF_DEBTOR: 'ATTORNEY_OF_DEBTOR',
	REPRESENTATIVE_OF_CREDITOR: 'REPRESENTATIVE_OF_CREDITOR',
	REPRESENTATIVE_OF_DEBTOR: 'REPRESENTATIVE_OF_DEBTOR',
	AUTHORITY: 'AUTHORITY',
	THIRD_PARTY_DEBTOR: 'THIRD_PARTY_DEBTOR',
	OTHER_PARTICIPANT: 'OTHER_PARTICIPANT',
	BAILIFF: 'BAILIFF',
	DETECTIVE: 'DETECTIVE',
	HEIR_OF_DEBTOR: 'HEIR_OF_DEBTOR',
	LIQUIDATOR: 'LIQUIDATOR',
	COURT: 'COURT',
	TRADE_CREDIT_INSURANCE: 'TRADE_CREDIT_INSURANCE'
}
type Participant = typeof Participant[keyof typeof Participant]
export type CtiAddress = {
	city?:string,
	street?: string,
	district?: string,
	postOfficeBoxNumber?: string,
	zipCode?: string,
	country?: string,
	type?: string
	houseNumber?: string,
	houseNumberSuffix?: string,
}
export type IkarosDebtor = string
export type CtiDebtor = {
	company?: {
		businessName: string
	},
	address?: CtiAddress,
	// todo: wrong letter case in verzeichnis-service:
	privateperson?: {
		surname: string
	}
}
export type IkarosPayload = InkassoNachricht
export type TransactionPayload = Omit<IkarosPayload, 'debtorName'|'collectionFileNumber'|'collectionFileUuid'|'procedureStep'> & {
	// todo: wrong name definition in verzeichnis-service:
	deptor: CtiDebtor,
	collectionFileNo: string,
	transactionId: string,
	timelineAttributes: {
		procedureStep: string,
		participantTo: Participant
	},
}
type Attributes = {
	inkassoType: string,
	referenceNumber: string,
	collectionFileNo: string,
	debtor: any,
	procedureStep: string,
	participantTo: Participant,
	transactionId?: string,
	collectionFileUuid?: string,
	displayName?: string
}

const CTI = 'CTI';
const IKAROS = 'Ikaros';

export class Inkasso extends Base {
	public attributes: Attributes
	public msgTemplate: string
	// type discriminator (same attribute name, different type and (constant) content):
	public nachrichtType: "INKASSO" = "INKASSO"

	constructor(data: Nachricht) {
		super(data)
		this.attributes = {
			referenceNumber: this.payload.referenceNumber,
			collectionFileNo: this.getCollectionFileNo(this.payload),
			collectionFileUuid: this.getCollectionFileUuid(this.payload),
			debtor: this.getDebtorDetails(this.payload),
			procedureStep: this.payload.timelineAttributes?.procedureStep,
			participantTo: this.payload.timelineAttributes?.participantTo,
			transactionId: this.getTransactionId(this.payload),
			displayName: this.getDisplayName(this.payload),
			inkassoType: this.getInkassoType(this.payload)
		} as Attributes
		this.msgTemplate = data.templateId || ''
	}

	getDisplayName(payload: TransactionPayload|IkarosPayload): string|undefined {

		if (isTransactionPayload(payload)) {
			return payload.deptor?.company?.businessName ?? payload.deptor?.privateperson?.surname
		}
		return payload.debtorName
	}

	getDebtorDetails(payload: TransactionPayload|IkarosPayload) {
		if (isTransactionPayload(payload)) {
			return {
				company: payload.deptor?.company?.businessName ? {
					businessName: payload.deptor?.company?.businessName,
				} : undefined,
				privatePerson: payload.deptor?.privateperson?.surname ? {
					surname: payload.deptor?.privateperson?.surname,
				} : undefined,
				address: this.anyAddressFieldSet(payload.deptor?.address) ? {
					city: payload.deptor?.address?.city,
					country: payload.deptor?.address?.country,
					district: payload.deptor?.address?.district,
					houseNumber: isTransactionPayload(payload) ? payload.deptor?.address?.houseNumber : undefined,
					houseNumberSuffix: isTransactionPayload(payload) ? payload.deptor?.address?.houseNumberSuffix : undefined,
					postOfficeBoxNumber: payload.deptor?.address?.postOfficeBoxNumber,
					street: payload.deptor?.address?.street,
					type: payload.deptor?.address?.type,
					zipCode: payload.deptor?.address?.zipCode
				} : undefined,
			}
		}
		return payload.debtorName
	}

	getLinkToCollectionFileDetails(t: (key: string) => string) {
		switch (this.attributes?.inkassoType) {
			case CTI:
				return `${t('link.inkassodetailseite')}?no_cache=1&transactionId=${this.attributes?.transactionId}`
			case IKAROS:
				return `/portal/inkasso/detailansicht-akte/${this.attributes?.collectionFileUuid}`
			default:

		}

	}

	private anyAddressFieldSet(address: CtiAddress|undefined) {
		if (address === undefined) {
			return false
		}
		if (
			[
				address.city,
				address.street,
				address.district,
				address.postOfficeBoxNumber,
				address.zipCode,
				address.country,
				address.type
			].filter(Boolean).length > 0
		) {
			return true
		}
		if (isCtiAddress(address)) {
			return [address.houseNumber, address.houseNumberSuffix].filter(Boolean).length > 0
		}
		return false
	}

	private getInkassoType(payload: IkarosPayload|TransactionPayload) {
		return isTransactionPayload(payload) ? CTI : IKAROS
	}

	private getCollectionFileUuid(payload: IkarosPayload|TransactionPayload) {
		return isTransactionPayload(payload) ? undefined : payload.collectionFileUuid
	}

	private getCollectionFileNo(payload: IkarosPayload|TransactionPayload) {
		return isTransactionPayload(payload) ? payload.collectionFileNo : payload.collectionFileNumber
	}
}
