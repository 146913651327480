<template>
	<div
		:id="id"
		class="custom-select"
	>
		<slot />
		<label
			v-if="label"
			:id="id + '.label'"
			class="input-label"
			>{{ label }}<template v-if="required"> *</template></label
		>
		<v-select
			:id="id + '.select'"
			v-model="input"
			:options="options"
			:reduce="reduceOption"
			:searchable="searchable"
			:clearable="false"
			:get-option-label="getOptionLabel"
			:data-qa="dataQa"
			:disabled="disabled"
			@option:selected="select"
		>
			<template #open-indicator="{ attributes }">
				<span v-bind="attributes" />
			</template>
			<template #no-options>
				<div />
			</template>
		</v-select>
		<div
			v-if="helpText"
			class="help-text"
		>
			{{ helpText }}
		</div>
		<div
			v-if="errorMessage"
			:id="id + '.error'"
			class="error-msg"
			:data-qa="errorMsgDataQaAttr"
		>
			<span>{{ errorMessage }}</span>
		</div>
		<!--
            Die 'hidden' Input-Felder wurden mit migriert, um den Aufbau der Selects im TYPO3 zu replizieren.
            Dies soll verhindern, dass bestehende Selenium-Tests angepasst werden müssen.
        -->
		<input
			v-model="input"
			type="hidden"
			:name="name"
			:data-qa="dataQa + '-input-value'"
		/>
	</div>
</template>

<script lang="ts" setup>
import { onMounted, toRef } from 'vue'
import { useField } from 'vee-validate'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

type SelectOption = {
	code: string | boolean | number | null
	label: string
	i18nKey?: string
}

const props = withDefaults(
	defineProps<{
		id: string
		modelValue: string | boolean | number | undefined | null
		options: SelectOption[]
		dataQa?: string
		errorMsgDataQa?: string
		label?: string
		name?: string
		required?: boolean
		disabled?: boolean
		searchable?: boolean
		helpText?: string
	}>(),
	{
		label: '',
		dataQa: 'tesadkga',
		errorMsgDataQa: '',
		name: '',
		searchable: true,
		required: false,
		disabled: false,
		helpText: '',
	},
)
const name = toRef(props, 'id')
const { value: input, errorMessage } = useField(name, undefined, {
	initialValue: toRef(() => props.modelValue).value,
	syncVModel: true,
})

const errorMsgDataQaAttr = props.errorMsgDataQa || (props.dataQa ? `${props.dataQa}-message` : '')

const emit = defineEmits<{
	(e: 'option:label', value: string | boolean | number): void
	(e: 'update:modelValue', value: string | boolean | number): void
}>()

function reduceOption(option: SelectOption) {
	return option.code
}

function getOptionLabel(option: SelectOption) {
	if (option.i18nKey) {
		return t(option.i18nKey)
	}
	return option.label
}

onMounted(() => {
	props.options.forEach((option) => {
		if (option.code === input.value) {
			select(option)
		}
	})
})

function select(option: SelectOption) {
	emit('option:label', option.label)
}

defineExpose({ input, select })
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars/colors.scss';

label {
	margin-bottom: 12px;
	line-height: 26px;
}
.has-error {
	.input-label {
		color: $clr-C-T2;
	}
}
</style>
