import VueScrollTo from 'vue-scrollto'

export const scrollTo = (element: Element | string) =>
	VueScrollTo.scrollTo(element, {
		container: 'body',
		duration: 500,
		easing: 'ease',
		offset: -60, // fixed header
		force: true,
		cancelable: true,
		onStart: false,
		onDone: false,
		onCancel: false,
		x: false,
		y: true,
	})
