<script lang="ts" setup>
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const props = defineProps({
	id: {
		type: String,
		default: '',
	},
	label: {
		type: String,
		default: '',
	},
	errors: {
		type: Array,
		default: () => [],
	},
	isRequired: {
		type: Boolean,
		default: false,
	},
})

const format = ref(t('date.formatOfDate'))

const lang = ref({
	formatLocale: {
		months: [
			t('date.january'),
			t('date.february'),
			t('date.march'),
			t('date.april'),
			t('date.may'),
			t('date.june'),
			t('date.july'),
			t('date.august'),
			t('date.september'),
			t('date.october'),
			t('date.november'),
			t('date.december'),
		],
		monthsShort: [
			t('date.januaryShort'),
			t('date.februaryShort'),
			t('date.marchShort'),
			t('date.aprilShort'),
			t('date.mayShort'),
			t('date.juneShort'),
			t('date.julyShort'),
			t('date.augustShort'),
			t('date.septemberShort'),
			t('date.octoberShort'),
			t('date.novemberShort'),
			t('date.decemberShort'),
		],
		weekdays: [
			t('date.sunday'),
			t('date.monday'),
			t('date.tuesday'),
			t('date.wednesday'),
			t('date.thursday'),
			t('date.friday'),
			t('date.saturday'),
		],
		weekdaysShort: [
			t('date.sundayShort'),
			t('date.mondayShort'),
			t('date.tuesdayShort'),
			t('date.wednesdayShort'),
			t('date.thursdayShort'),
			t('date.fridayShort'),
			t('date.saturdayShort'),
		],
		weekdaysMin: [
			t('date.sundayMin'),
			t('date.mondayMin'),
			t('date.tuesdayMin'),
			t('date.wednesdayMin'),
			t('date.thursdayMin'),
			t('date.fridayMin'),
			t('date.saturdayMin'),
		],
		firstDayOfWeek: parseInt(t('date.firstDayOfWeek')),
		firstWeekContainsDate: parseInt(t('date.firstWeekContainsDate')),
	},
})
</script>

<template>
	<div
		:id="props.id"
		class="crefo-input"
		:class="{ 'has-error': errors && errors[0] }"
	>
		<div class="input-group">
			<label
				v-if="props.label"
				:id="props.id + '.label'"
				>{{ label }}<template v-if="isRequired">*</template></label
			>
			<date-picker
				:id="props.id + '.date-picker'"
				:format="format"
				:lang="lang"
				class="crefo-datepicker"
				v-bind="$attrs"
			/>
			<div
				v-if="errors[0]"
				:id="props.id + '.error'"
				class="error-msg"
			>
				<span>{{ errors[0] }}</span>
			</div>
		</div>
	</div>
</template>

<style scoped>
.error-msg {
	display: block !important;
}
</style>
