<template>
	<div class="container pt">
		<div class="row">
			<div class="col-12">
				<div class="breadcrumbs-wrapper">
					<ul class="breadcrumbs">
						<li>
							<a href="/">{{ t('breadcrumbs.home') }}</a>
						</li>
						<li>
							<router-link :to="{ name: 'index' }">
								{{ t('appname') }}
							</router-link>
						</li>
						<li>{{ t('breadcrumbs.accessNotAllowed') }}</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-lg-10">
				<h1 data-qa="headline">
					{{ t('posteingang.accessNotAllowed') }}
				</h1>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-lg-10 mb">
				{{ t('posteingang.accessNotAllowedMessage') }}
			</div>
			<div class="col-12 col-lg-10">
				<a
					href="/"
					role="button"
					class="btn btn-default"
					data-qa="back-from-no-access"
				>
					{{ t('posteingang.accessNotAllowedButton') }}
				</a>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
