import { ref } from 'vue'
import {defineStore, storeToRefs} from 'pinia'
import * as api from '@/services'
import vuecookie from 'vue-cookies'
import dayjs from 'dayjs'
import { useMemberStructureStore } from './memberStructure'
import { useUserStore } from './user'
import { Auskunft } from '@/models/Messages/Auskunft'
import { Inkasso } from '@/models/Messages/Inkasso'
import { Signal } from '@/models/Messages/Signal'

import type { Nachricht, Nachrichtenliste } from '@/types/messageBox/messages'
import type {
	Kategorie,
	NachrichtKategorie,
	PageSizes,
	PosteingangSearchBase,
	Sortings
} from '@/types/messageBox/MessageSearch'
import { PosteingangSearchRequest, PosteingangSaveSearchRequest } from '@/types/messageBox/MessageSearch'
import {TypisierteNachricht} from '@/models/Messages/TypisierteNachricht'

export const useMessagesStore = defineStore('messages', () => {

	// stores
	const memberStructureStore = useMemberStructureStore()
	const { memberStructure, selectedMembers, savedMembers } = storeToRefs(memberStructureStore)
	const userStore = useUserStore()
	const { user } = storeToRefs(userStore)

	// state
	const nachrichtenliste = ref<Array<TypisierteNachricht>>([])
	const isFetchingData = ref(false)
	const selectedNachricht = ref<TypisierteNachricht>()
	const getTemplate = ref<string>()
	const gesamtAnzahlNachrichten = ref(0)
	const pagination = ref<{ pageSize: PageSizes, pageStartIndex: number }>({
		pageSize: 10,
		pageStartIndex: 0,
	})
	const sort = ref('CREATION_TIME_DESC')
	const searchConfig = ref<PosteingangSearchBase>({
		nachrichtType: 'ALLE_NACHRICHTEN', // [ ALLE_NACHRICHTEN, FIRMEMAUSKUENFTE, INKASSO, SIGNALE ]
		leseStatus: 'ALLE', // [ ALLE, GELESEN, UNGELESEN ]
		zeitraum: 'ALLE_NACHRICHTEN', // [ ALLE_NACHRICHTEN, LETZTEN_30_TAGE, LETZTEN_60_TAGE, LETZTEN_90_TAGE, AKTUELLES_JAHR, VORJAHR, EIGENER_ZEITRAUM ]
		vonBis: {
			beginn: '', // "2022-02-11T16:20:56.936Z"
			ende: '', // "2022-02-11T16:20:56.936Z"
		},
		ansicht: 'ALLE',
		einfeldsuche: '',
		kategorien: []
	})
	const memberStructureLoaded = ref(false)
	const multiSelectedMessages = ref<Array<Nachricht>>([])
	const mobileSelected = ref(false)

	// actions
	function getMitgliedsNummern() {
		
		let mitgliedsNummern: string[] = []

		if (memberStructure.value.length) {
			// memberStructure === ich und keine Submember
			if (
				memberStructure.value.length === 1 &&
				memberStructure.value[0].memberId === user.value.cr_membernumber
			) {
				mitgliedsNummern = user.value.cr_membernumber ? [user.value.cr_membernumber] : []
			}

			if (selectedMembers.value.length > 0) {
				mitgliedsNummern = selectedMembers.value.map(selectedMember => selectedMember.memberId)
			} else if (savedMembers.value.length > 0) {
				memberStructure.value.map(member => {
					// @ts-ignore
					if (savedMembers.value.includes(member.memberId)) {
						mitgliedsNummern.push(member.memberId)
					}
				})
			}
		} else {
			mitgliedsNummern = user.value.cr_membernumber ? [user.value.cr_membernumber] : []
		}

		return mitgliedsNummern
	}

	function handlePosteingangFetchResponse(data: Nachrichtenliste) {
		if (data.nachrichtenliste.length === 0 && pagination.value.pageStartIndex != 0) {
			pagination.value.pageStartIndex = pagination.value.pageStartIndex - 1
			fetchPosteingang()
			return
		} else if (data.nachrichtenliste.length > 0) {
			nachrichtenliste.value = data.nachrichtenliste.map((nachricht) => {

				switch (nachricht.nachrichtType) {
					case 'FIRMENAUSKUENFTE':
						return new Auskunft(nachricht)
					case 'INKASSO':
						return new Inkasso(nachricht)
					case 'SIGNALE':
						return new Signal(nachricht)
					default:
						throw new Error('unknown Nachricht type: ' + nachricht.nachrichtType)
				}
			})
		}

		gesamtAnzahlNachrichten.value = data.gesamtAnzahlNachrichten

		// check für selektierten Eintrag in Detailansicht
		if (nachrichtenliste.value.length > 0) {
			const filteredMessagesList = nachrichtenliste.value.filter((item) => {
				return selectedNachricht.value && item.nachrichtId === selectedNachricht.value.nachrichtId
			})
			if (filteredMessagesList?.length === 0) {
				selectedNachricht.value = undefined
			}
		}
	}

	function setNachrichtAsRead(nachrichtId: string) {
		nachrichtenliste.value.forEach(nachricht => {
			if (nachricht.nachrichtId === nachrichtId) {
				nachricht.isRead = true
			}
		})
	}

	function setNachrichtAsUnread(nachrichtId: string) {
		// Maybe a solution with better performance?
		// const nachricht = this.nachrichtenliste.filter((nachricht) => nachricht.nachrichtId === nachrichtId)
		// nachricht.isRead = false
		nachrichtenliste.value.forEach(nachricht => {
			if (nachricht.nachrichtId === nachrichtId) {
				nachricht.isRead = false
			}
		})
	}

	function setNachrichtKategorie(nachrichtId: string, kategorie: Kategorie) {
		try {
			const params: NachrichtKategorie = {
				nachrichtId: nachrichtId,
				kategorie: kategorie
			}
			
			api.saveNachrichtKategorie(params)
				.then((response) => {
					nachrichtenliste.value.map(nachricht => {
						if (nachricht.nachrichtId === response.data.nachrichtId) {
							nachricht.kategorie = response.data.kategorie
							nachricht.kategorieGesetztVon = response.data.kategorie_gesetzt_von
						}
					})
				})
				.catch((err) => {
					console.log(err)
				})
		} catch (error) {
			console.log(error)
		}
	}

	function reloadPosteingang() {
		pagination.value.pageStartIndex = 0
		fetchPosteingang()
	}

	function readCookies() {
		// @ts-ignore
		if (vuecookie.get('pageSize') !== null) {
			// @ts-ignore
			setNewPageSize(vuecookie.get('pageSize') as PageSizes)
		}

		// @ts-ignore
		if (vuecookie.get('categories') !== null) {
			// @ts-ignore
			setNewSorting(vuecookie.get('categories'))
		}
	}

	async function fetchPosteingang() {
		const mitgliedsNummern = getMitgliedsNummern()

		const vonbisBeginn = searchConfig.value.vonBis.beginn
			? dayjs(searchConfig.value.vonBis.beginn).format('YYYY-MM-DDTHH:mm:ss')
			: ''

		const vonbisEnde = searchConfig.value.vonBis.ende
			? dayjs(searchConfig.value.vonBis.ende)
					.add(1, 'day')
					.subtract(1, 'millisecond')
					.format('YYYY-MM-DDTHH:mm:ss')
			: ''

		const params = {
			...pagination.value,
			nachrichtType: searchConfig.value.nachrichtType,
			leseStatus: searchConfig.value.leseStatus,
			zeitraum: searchConfig.value.zeitraum,
			vonBis: {
				beginn: vonbisBeginn,
				ende: vonbisEnde,
			},
			ansicht: searchConfig.value.ansicht,
			einfeldsuche: searchConfig.value.einfeldsuche || '',
			sort: sort.value,
			mitgliedsnummern: mitgliedsNummern,
			kategorien: searchConfig.value.kategorien,
		} as PosteingangSearchRequest

		try {
			const response = await api.fetchPosteingang(params)
			handlePosteingangFetchResponse(response.data)
			if (multiSelectedMessages.value && multiSelectedMessages.value.length > 0) {
				multiSelectedMessages.value = []
			}
		} catch(er) {
			//TODO: handle Error
			console.log(er)
			// this.handlePosteingangFetchResponse(response.data)
			selectedNachricht.value = undefined
		}
	}

	function getSearchFilter() {
		return api.getSearchFilter()
	}

	// @ts-ignore
	async function markMessageAsRead(nachricht: Nachricht) {
		if (nachricht && !nachricht.isRead) {
			try {
				await api.markSelectedNachrichtenAsRead([nachricht.nachrichtId])
			} catch (err) {
				//TODO: HANDLE ERROR
				console.log(err)
				return
			}

			setNachrichtAsRead(nachricht.nachrichtId)

			if (user.value.cr_membernumber) {
				memberStructureStore.getMessageUnreadCountAll(user.value.cr_membernumber)
				memberStructureStore.updateBubbles(user.value.cr_membernumber)
			}
		}
	}

	// @ts-ignore
	async function markMessageAsUnread(nachricht: Nachricht | undefined) {
		if (nachricht && nachricht.isRead) {
			try {
				await api.markSelectedNachrichtenAsUnread([nachricht.nachrichtId])
			} catch (e) {
				//TODO: HANDLE ERROR
				console.log(e)
				return
			}

			setNachrichtAsUnread(nachricht.nachrichtId)
			if (user.value.cr_membernumber) {
				memberStructureStore.getMessageUnreadCountAll(user.value.cr_membernumber)
				memberStructureStore.updateBubbles(user.value.cr_membernumber)
			}
		}
	}

	function markSelectedMessagesAsRead(nachrichtenListe: Nachricht[]) {
		if (nachrichtenListe && nachrichtenListe.length > 0) {
			const items: Nachricht[] = []
			const markReadItems: string[] = []
			
			nachrichtenListe.filter((item: Nachricht) => {
				if (!item.isRead) {
					items.push(item)
					markReadItems.push(item.nachrichtId)
				}
			})

			if (items.length > 0) {
				api.markSelectedNachrichtenAsRead(markReadItems)
					.then(() => {
						items.map((nachricht) => {
							setNachrichtAsRead(nachricht.nachrichtId)
						})
						if (user.value.cr_membernumber) {
							memberStructureStore.getMessageUnreadCountAll(user.value.cr_membernumber)
							memberStructureStore.updateBubbles(user.value.cr_membernumber)
						}

						multiSelectedMessages.value = []
					})
					.catch((e) => {
						console.log(e)
					})
			}
		}
	}

	// @ts-ignore
	async function markSelectedMessagesAsUnread(nachrichtenListe: Nachricht[]) {
		if (nachrichtenListe && nachrichtenListe.length > 0) {
			
			const items: Nachricht[] = []
			const markReadItems: string[] = []
			
			nachrichtenListe.filter((item) => {
				if (item.isRead) {
					items.push(item)
					markReadItems.push(item.nachrichtId)
				}
			})

			if (items.length > 0) {
				api.markSelectedNachrichtenAsUnread(markReadItems)
					.then(() => {
						items.map((nachricht) => {
							setNachrichtAsUnread(nachricht.nachrichtId)
						})
						if (user.value.cr_membernumber) {
							memberStructureStore.getMessageUnreadCountAll(user.value.cr_membernumber)
							memberStructureStore.updateBubbles(user.value.cr_membernumber)
						}

						multiSelectedMessages.value = []
					})
					.catch((e) => {
						console.log(e)
					})
			}
		}
	}

	async function deleteMessages(nachrichten: Nachricht[]) {
		if (nachrichten && nachrichten.length > 0) {
			const listOfNachrichtenIds = nachrichten.map((item) => item.nachrichtId)

			try {
				await api.markNachrichtAsDeleted(listOfNachrichtenIds)
				fetchPosteingang()
				if (user.value.cr_membernumber) {
					await memberStructureStore.getMemberStructure(user.value.cr_membernumber)
					await memberStructureStore.getMessageUnreadCountAll(user.value.cr_membernumber)
				}
				multiSelectedMessages.value = []
				selectedNachricht.value = undefined
			} catch (e) {
				console.log(e)
			}
		}
	}

	function setNewPageSize(newPageSize: PageSizes) {
		if (pagination.value.pageStartIndex > 0) {
			pagination.value.pageStartIndex = 0
		}
		
		pagination.value.pageSize = newPageSize
		// @ts-ignore
		vuecookie.set('pageSize', newPageSize, '24d')

		selectedNachricht.value = undefined
	}

	function setNewSorting(newSorting: Sortings) {
		if (pagination.value.pageStartIndex > 0) {
			pagination.value.pageStartIndex = 0
		}
		sort.value = newSorting
		selectedNachricht.value = undefined
	}

	function saveSearchFilter() {
		const vonbisBeginn = searchConfig.value.vonBis.beginn
			? dayjs(searchConfig.value.vonBis.beginn).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
			: ''
		const vonbisEnde = searchConfig.value.vonBis.ende
			? dayjs(searchConfig.value.vonBis.ende)
					.add(1, 'day')
					.subtract(1, 'millisecond')
					.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
			: ''

		const saveFilter  = {
			nachrichtType: searchConfig.value.nachrichtType,
			timePeriod: searchConfig.value.zeitraum,
			vonBis: {
				beginn: vonbisBeginn,
				ende: vonbisEnde,
			},
			leseStatus: searchConfig.value.leseStatus,
			ansicht: searchConfig.value.ansicht,
			kategorien: searchConfig.value.kategorien
		} as PosteingangSaveSearchRequest

		return api.saveSearchFilter(saveFilter)
	}

	function setSavedSearchFilter(params: PosteingangSaveSearchRequest) {
		searchConfig.value.nachrichtType = params.nachrichtType || 'ALLE_NACHRICHTEN'
		searchConfig.value.leseStatus = params.leseStatus || 'ALLE'
		searchConfig.value.zeitraum = params.timePeriod || 'ALLE_NACHRICHTEN'
		searchConfig.value.vonBis.beginn = params.vonBis.beginn ? new Date(params.vonBis.beginn).toString() : ''
		searchConfig.value.vonBis.ende = params.vonBis.ende ? new Date(params.vonBis.ende).toString() : ''
		searchConfig.value.ansicht = params.ansicht || 'ALLE'
		searchConfig.value.kategorien = params.kategorien || []
		searchConfig.value.einfeldsuche = params.einfeldsuche
	}

	// actions

	return {
		nachrichtenliste,
		isFetchingData,
		selectedNachricht,
		getTemplate,
		gesamtAnzahlNachrichten,
		pagination,
		sort,
		searchConfig,
		memberStructureLoaded,
		multiSelectedMessages,
		mobileSelected,
		handlePosteingangFetchResponse,
		setNachrichtKategorie,
		reloadPosteingang,
		readCookies,
		fetchPosteingang,
		getSearchFilter,
		markMessageAsRead,
		markMessageAsUnread,
		markSelectedMessagesAsRead,
		markSelectedMessagesAsUnread,
		deleteMessages,
		setNewPageSize,
		setNewSorting,
		saveSearchFilter,
		setSavedSearchFilter
	}

})
