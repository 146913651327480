<template>
	<ul>
		<li
			v-for="(item, index) in nachrichtenliste"
			:key="item.nachrichtId"
			class="posteingang-inbox__message-list__item"
			:class="{
				unread: !item.isRead,
				selected: item.nachrichtId === getSelectedId,
				noBorder: index === lastItem,
			}"
			data-qa="posteingang-message-list-item"
		>
			<div class="posteingang-inbox__message-list__item-content">
				<div class="posteingang-inbox__message-list__item-content__selection">
					<div class="crefo-input">
						<div class="input-group">
							<div class="checkbox-group">
								<div class="input-wrapper">
									<div class="crefo-checkbox">
										<input
											:id="'checkbox_' + item.nachrichtId"
											v-model="multiSelectedMessages"
											type="checkbox"
											:value="item"
											data-qa="posteingang-message-list-selection__checkbox"
										/>
										<label :for="'checkbox_' + item.nachrichtId" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="posteingang-inbox__message-list__item-content__content"
					@click="markNachricht(item)"
				>
					<div class="posteingang-inbox__message-list__item-content__readstatus" />
					<div class="posteingang-inbox__message-list__item-content__data">
						<div class="posteingang-inbox__message-list__item-content__data__first-line">
							<div class="posteingang-inbox__message-list__item-content__data__first-line__intro">
								<div
									class="posteingang-inbox__message-list__item-content__data__first-line__type"
									data-qa="posteingang-message-list-first-line__type"
								>
									{{ getNachrichtType(item) }}
								</div>
								<div
									v-if="item.attributes && item.attributes.referenceNumber"
									class="posteingang-inbox__message-list__item-content__data__first-line__zusatzinfo ellipsis"
									data-qa="posteingang-message-list-first-line__zusatzinfo"
								>
									{{ item.attributes.referenceNumber }}
								</div>
							</div>

							<div class="posteingang-inbox__message-list__item-content__data__first-line__data-category">
								<div
									class="posteingang-inbox__message-list__item-content__data__first-line__data-category__category"
									data-qa="posteingang-message-list-first-line__category"
								>
									<font-awesome-icon
										icon="fa-solid fa-pennant"
										:class="'posteingang-inbox__message-pennant--' + item.kategorie"
										data-qa="pennant-picker__list-item__icon__category--color"
									/>
								</div>

								<div
									class="posteingang-inbox__message-list__item-content__data__first-line__data-category__date"
									data-qa="posteingang-message-list-first-line__date"
								>
									<CrefoDate
										data-qa="posteingang-message-list-crefo-date"
										:date="item.creationTime"
									/>
								</div>
							</div>
						</div>
						<div class="posteingang-inbox__message-list__item-content__data__second-line">
							<span
								class="posteingang-inbox__message-list__item-content__data__second-line__name ellipsis"
								data-qa="posteingang-message-list-second-line__name"
							>
								{{ getHeadline(item) }}
							</span>
						</div>
						<div class="posteingang-inbox__message-list__item-content__data__third-line">
							<MessageListBubble :payload="item" />
						</div>
					</div>
				</div>
			</div>
		</li>
	</ul>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import CrefoDate from '@/components/atoms/CrefoDate/index.vue'
import MessageListBubble from '@/components/atoms/MessageListBubble/index.vue'
import { useMessagesStore } from '@/stores/messages'
import { useI18n } from 'vue-i18n'
import {Nachricht} from '@/types/messageBox/messages'
import {TypisierteNachricht} from '@/models/Messages/TypisierteNachricht'
import {storeToRefs} from 'pinia'
const { t, te } = useI18n()

const messagesStore = useMessagesStore()
const { mobileSelected, multiSelectedMessages, selectedNachricht } = storeToRefs((messagesStore))
const props = defineProps<{
	nachrichtenliste: Array<TypisierteNachricht>
}>()

const getNachrichtType = computed(() => {
	return (item: Nachricht) => {
		let newReturnValue = ''

		switch (item.nachrichtType) {
			case 'FIRMENAUSKUENFTE':
				newReturnValue = t('auskunft')
				break
			case 'INKASSO':
				newReturnValue = t('inkasso')
				break
			case 'SIGNALE':
				newReturnValue = t('signal')
		}
		return newReturnValue
	}
})

const getSelectedId = computed(() => {
	return selectedNachricht.value ? selectedNachricht.value.nachrichtId : null
})

const lastItem = computed(() => {
	return props.nachrichtenliste?.length ?? 0 - 1
})

function getHeadline(item: TypisierteNachricht) {
	let headline, template, str
	switch (item.nachrichtType) {
		case 'INKASSO':
			headline = te('timelineEntryType.' + item.templateId + '.subject')
				? t('timelineEntryType.' + item.templateId + '.subject')
				: t('timelineEntryType.NOT_FOUND')
			break
		default:
			template = item.msgTemplate
			if (template) {
				str = te(item.nachrichtType + '.' + template + '.headline')
					? t(item.nachrichtType + '.' + template + '.headline')
					: ''
				headline = `${str} ${item.headlineText ? '| ' + item.headlineText : ''}`
			} else {
				headline = 'Unbekannter Nachrichtentyp'
			}
	}
	return headline
}

async function markNachricht(nachricht: TypisierteNachricht) {
	// TODO:
	// todo-sk: eventuell muss hier noch nachgearbeitet werden, wenn die templates nicht rechtzeitig gewählt sind
	selectedNachricht.value = nachricht
	mobileSelected.value = true
	await messagesStore.markMessageAsRead(nachricht)
}
</script>

<style lang="scss" scoped>
@import '@/components/molecules/PennantPicker/category-color.scss';
@import '@/assets/scss/vars/colors';

.posteingang-inbox__message-list__item {
	cursor: pointer;
	border-bottom: 1px solid $clr-brand-light-grey-2;
	&.noBorder {
		border-bottom: none;
	}
	&:hover {
		background-color: $clr-brand-blue;

		.posteingang-inbox__message-list__item-content {
			color: $white;
			&__data__first-line__date,
			&__data__second-line__name {
				color: $white;
			}
		}
	}
}
.posteingang-inbox {
	&__message-pennant {
		@include category-color;
	}
}
.posteingang-inbox__message-list__item-content {
	display: flex;
	line-height: 20px;
	color: $clr-brand-dark-grey;
	flex-wrap: wrap;
	position: relative;
	height: 109px;

	.posteingang-inbox__message-list__item-content__selection {
		position: absolute;
		width: 15px;
		height: 15px;
		top: 22px;
		left: 20px;
		display: inline-block;
	}

	.crefo-input {
		position: absolute;
		width: 15px;
		height: 15px;
		left: 0;
		top: 0;
		z-index: 5;
		.input-group {
			width: 15px;
		}
	}
	.crefo-input [type='checkbox']:checked + label,
	.crefo-input [type='checkbox']:not(:checked) + label {
		padding-left: 25px;
		line-height: 30px;
		cursor: pointer;
	}

	.crefo-input [type='checkbox']:checked + label:before,
	.crefo-input [type='checkbox']:not(:checked) + label:before {
		width: 15px;
		height: 15px;
	}

	.crefo-input [type='checkbox']:checked + label:after,
	.crefo-input [type='checkbox']:not(:checked) + label:after {
		top: 2px;
		left: 2px;
		font-size: 0.8rem;
		line-height: 0.8rem;
	}

	.crefo-checkbox {
		display: inherit;
		margin-right: 0;
	}

	&__readstatus {
		width: 10px;
		margin: 0 10px 0 10px;
		display: inline-flex;
		padding-top: 5px;
	}

	&__content {
		width: 100%;
		display: flex;
		padding: 20px 20px 20px 35px;
	}

	&__data {
		width: 470px;
		&__first-line {
			display: flex;
			width: 100%;
			justify-content: space-between;
			&__intro {
				display: flex;
				overflow: hidden;
			}
			&__data-category {
				display: inline-flex;
				align-items: center;
				&__category {
					font-size: 15px;
					padding-left: 10px;
				}
				&__date {
					font-size: 12px;
					padding-left: 10px;
					.fal.fa-user-friends,
					.fas.fa-thumbtack {
						margin-right: 5px;
					}
				}
			}

			&__zusatzinfo {
				&:before {
					content: '|';
					position: relative;
					padding: 0 5px;
				}
			}
		}

		&__second-line {
			display: flex;
			width: 100%;
			padding: 5px 0;
			&__name {
				white-space: nowrap;
				overflow: hidden;
				-o-text-overflow: ellipsis;
				text-overflow: ellipsis;
				font-weight: 700;
			}
		}

		&__third-line {
			display: flex;
			margin-top: 5px;
			&__member {
				line-height: 14px;
				padding: 3px 6px;
				border-radius: 5px;
				color: $clr-brand-darkest-blue;
				font-size: 12px;
				background-color: $clr-brand-light-blue-2;
			}
		}
	}
}

.unread {
	.posteingang-inbox__message-list__item-content {
		&__data__first-line {
			font-weight: 600;
		}
		&__readstatus:before {
			content: ' ';
			width: 10px;
			height: 10px;
			background: $clr-C-T1;
			border-radius: 50%;
			display: inline-flex;
		}
		&__data__first-line__date,
		&__data__second-line__name {
			color: $clr-brand-blue;
		}
	}
}
.selected {
	background-color: $clr-brand-light-blue-1;
}
</style>
