<template>
	<div class="container pt">
		<div class="row">
			<div class="col-12">
				<div class="breadcrumbs-wrapper">
					<ul class="breadcrumbs">
						<li>
							<a href="/">{{ t('breadcrumbs.home') }}</a>
						</li>
						<li>{{ t('appname') }}</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12 flex space-between">
				<div class="headline-area">
					<h1 data-qa="headline">
						{{ t('appname') }}
					</h1>
				</div>
			</div>
		</div>
		<MemberStructure />
		<MessageSearch :key="dateLocale" />
		<TabNavigation />
		<Messagebox v-if="memberStructureLoaded" />
	</div>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import TabNavigation from '@/components/molecules/TabNavigation/index.vue'
import Messagebox from '@/components/organisms/Messagebox/index.vue'
import MessageSearch from '@/components/molecules/MessageSearch/index.vue'
import MemberStructure from '@/components/organisms/MemberStructure/index.vue'
import { useUserStore } from '@/stores/user'
import { useMessagesStore } from '@/stores/messages'
import { useMemberStructureStore } from '@/stores/memberStructure'
import { useVCStore } from '@/stores/vc'
import {storeToRefs} from 'pinia'

const { t } = useI18n()
const { dateLocale, user } = storeToRefs(useUserStore())
const messagesStore = useMessagesStore()
const { memberStructureLoaded } = storeToRefs(messagesStore)
const memberStructureStore = useMemberStructureStore()
const vcStore = useVCStore()

onMounted(() => {
	window.addEventListener(
		'reloadPosteingang',
		() => {
			refreshPosteingang()
		},
		false,
	)
})

function refreshPosteingang() {
	memberStructureStore.getMemberStructure(user.value.cr_membernumber!)
	memberStructureStore.getMessageUnreadCountAll(user.value.cr_membernumber!)
	messagesStore.reloadPosteingang()
	vcStore.getVC()
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/main.scss';

$posteingang-height: 1120px;

.selectActive {
	.posteingang-inbox {
		.posteingang-inbox__message-list {
			display: none;
			@include media-up(md) {
				display: block;
			}
		}
		.posteingang-inbox__message-details {
			display: flex;

			@include media-up(md) {
				display: block;
			}
		}
	}
}

.posteingang-inbox {
	height: $posteingang-height;
	.posteingang-inbox__message-list {
		overflow-x: auto;
		height: $posteingang-height;
	}

	.posteingang-inbox__message-details {
		display: none;
		border-left: none;

		@include media-up(md) {
			height: $posteingang-height;
			border-left: 1px solid $clr-brand-light-grey-2;
		}
	}

	.flex {
		display: flex;
	}
	.center-xy {
		justify-content: center;
		align-items: center;
		height: 100%;
	}
}

.posteingang-inbox--content {
	display: flex;
	height: 100%;
	flex-direction: column;
}

.posteingang-placeholder {
	display: flex;
	justify-content: center;
	padding-top: 150px;
	text-align: center;
}
</style>
