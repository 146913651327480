import { h, createApp } from 'vue'
import singleSpaVue, { type SingleSpaVueLifecycles } from 'single-spa-vue'
import initVue from '@/initVue'

import 'vue-select/dist/vue-select.css'

import App from '@/App.vue'

const vueLifecycles: SingleSpaVueLifecycles = singleSpaVue({
	createApp,
	appOptions: {
		render() {
			return h(App)
		},
	},

	handleInstance: (app) => {
		initVue(app)
	},
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
