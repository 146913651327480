<template>
	<div class="posteingang-inbox__message-details">
		<div
			v-if="selectedNachricht"
			class="posteingang-inbox__message-details-messages"
			data-qa="posteingang-inbox__message-details-messages"
		>
			<div class="posteingang-inbox__message-details-messages__tools">
				<div
					class="posteingang-inbox__message-details-messages__tools__item posteingang-inbox__message-details-messages__tools__item__back-to-list posteingang-inbox__message-details-messages__tools__item--colored"
				>
					<i
						class="crefo-ui-icon icon-arrow-prev back-to-list"
						aria-hidden="true"
						@click="backToList"
					/>
				</div>

				<div
					v-if="bearbeitetVon"
					class="posteingang-inbox__message-details-messages__tools__item posteingang-inbox__message-details-messages__tools__item__last-edited"
				>
					<div :title="`${bearbeitetVonTitle}`">
						{{ t('Posteingang.PennantPicker.kategorieGesetztVon') }}
						<span data-qa="bearbeitetVon">{{ bearbeitetVon }}</span>
					</div>
				</div>

				<div class="posteingang-inbox__message-details-messages__tools__item">
					<PennantPicker
						:selected-nachricht="selectedNachricht"
						:key="componentKey"
					/>
				</div>

				<div
					class="posteingang-inbox__message-details-messages__tools__item posteingang-inbox__message-details-messages__tools__item--colored"
				>
					<font-awesome-icon
						v-if="!selectedNachricht.isRead"
						icon="fa-light fa-envelope-open"
						data-qa="posteingang-inbox__message-details-messages__tools__make_read"
						:title="t('Posteingang.MessageDetail.Button.Unread.Alt.Title')"
						@click="markMessageReadOrUnread"
					/>
					<font-awesome-icon
						v-if="selectedNachricht.isRead"
						icon="fa-light fa-envelope"
						data-qa="posteingang-inbox__message-details-messages__tools__make_unread"
						:title="t('Posteingang.MessageDetail.Button.Read.Alt.Title')"
						@click="markMessageReadOrUnread"
					/>
				</div>

				<div
					class="posteingang-inbox__message-details-messages__tools__item posteingang-inbox__message-details-messages__tools__item--colored"
				>
					<font-awesome-icon
						icon="fa-light fa-trash-can"
						class="active"
						data-qa="posteingang-inbox__message-details-messages__tools__delete"
						@click="showDeleteConfirmation(selectedNachricht)"
					/>
				</div>
			</div>
			<div class="posteingang-inbox__message-details-messages__intro">
				<div
					v-if="!selectedNachricht.isRead"
					class="posteingang-inbox__message-details-messages__intro__readstatus"
				/>
				<div
					class="posteingang-inbox__message-details-messages__intro__type"
					data-qa="posteingang-inbox-messageType"
				>
					{{ getNachrichtType(selectedNachricht) }}
				</div>
				<div
					v-if="selectedNachricht.attributes.referenceNumber"
					class="posteingang-inbox__message-details-messages__intro__zusatzinfo ellipsis"
				>
					{{ selectedNachricht.attributes.referenceNumber }}
				</div>
			</div>
			<div class="posteingang-inbox__message-details-messages__date">
				<crefo-date :date="selectedNachricht.creationTime" />
			</div>
			<div class="posteingang-inbox__message-details-messages__message">
				<div class="posteingang-inbox__message-details-messages__message__title">
					<h2 data-qa="posteingang-inbox__message-details-headline">
						{{ getHeadline }}
					</h2>
				</div>
				<div class="posteingang-inbox__message-details-messages__message__content">
					<Message
						:nachricht="selectedNachricht as TypisierteNachricht"
						:template="selectedNachricht.msgTemplate"
						data-qa="posteingang-inbox__message-details-messages__message"
					/>
				</div>
				<div class="posteingang-inbox__message-details-messages__message__footer">
					<MessageFooter />
				</div>
			</div>
		</div>
		<div
			v-else
			class="posteingang-inbox__message-details-no-messages"
		>
			<placeholder-image />
		</div>
	</div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import Message from '@/components/molecules/Message/index.vue'
import CrefoDate from '@/components/atoms/CrefoDate/index.vue'
import PlaceholderImage from '@/components/atoms/PlaceholderImage/index.vue'
import MessageFooter from '@/components/molecules/MessageFooter/index.vue'
import PennantPicker from '@/components/molecules/PennantPicker/index.vue'
import { useMessagesStore } from '@/stores/messages'
import { useI18n } from 'vue-i18n'
import {Nachricht} from '@/types/messageBox/messages'
import {storeToRefs} from 'pinia'
import {TypisierteNachricht} from '@/models/Messages/TypisierteNachricht'

const messagesStore = useMessagesStore()
const { mobileSelected, selectedNachricht } = storeToRefs(messagesStore)
const { t, te, locale } = useI18n()

const componentKey = ref(0)

const getHeadline = computed(() => {
	let headline, template, str

	switch (selectedNachricht.value?.nachrichtType) {
		case 'INKASSO':
			headline = te('timelineEntryType.' + selectedNachricht.value?.templateId + '.subject')
				? t('timelineEntryType.' + selectedNachricht.value?.templateId + '.subject')
				: t('timelineEntryType.NOT_FOUND')
			break
		default:
			template = selectedNachricht.value?.msgTemplate
			if (template) {
				str = te(selectedNachricht.value?.nachrichtType + '.' + template + '.headline')
					? t(selectedNachricht.value?.nachrichtType + '.' + template + '.headline')
					: 'Unbekannter Nachrichtentyp'
				headline = [str, selectedNachricht.value?.headlineText].filter(Boolean).join(' | ')
			} else {
				headline = 'Unbekannter Nachrichtentyp'
			}
			break
	}
	return headline
})

const getNachrichtType = computed(() => {
	return (item: Nachricht) => {
		let newReturnValue = ''
		switch (item.nachrichtType) {
			case 'FIRMENAUSKUENFTE':
				newReturnValue = t('auskunft')
				break
			case 'INKASSO':
				newReturnValue = t('inkasso')
				break
			case 'SIGNALE':
				newReturnValue = t('signal')
		}
		return newReturnValue
	}
})

const bearbeitetVon = computed(() => {
	return selectedNachricht.value?.kategorieGesetztVon
		? selectedNachricht.value.kategorieGesetztVon
		: null
})

const bearbeitetVonTitle = computed(() => {
	return selectedNachricht.value?.kategorieGesetztVon
		? `${t('Posteingang.PennantPicker.kategorieGesetztVonTitle')} ${selectedNachricht.value.kategorieGesetztVon}`
		: null
})

function showDeleteConfirmation(nachricht: Nachricht) {
	window.CrefoUI.Modal.show({
		title: t('Posteingang.Inbox.ShowDeleteConfirmation.Title'),
		content: t('Posteingang.Inbox.ShowDeleteConfirmation.Content'),
		buttons: {
			confirm: t('Posteingang.Inbox.ShowDeleteConfirmation.Confirm'),
			cancel: t('Posteingang.Inbox.ShowDeleteConfirmation.Cancel'),
		},
		confirmCallback: async () => {
			await messagesStore.deleteMessages([nachricht])
		},
		cancelCallback: function () {},
		icon: 'info',
		dataQa: 'posteingang-inbox-confirmation-dialog',
	})
}

function backToList() {
	mobileSelected.value = false
}

function markMessageReadOrUnread() {
    if (selectedNachricht.value !== undefined) {
        if (selectedNachricht.value.isRead) {
            messagesStore.markMessageAsUnread(selectedNachricht.value)
        } else {
            messagesStore.markMessageAsRead(selectedNachricht.value)
        }
    }
}

watch(locale, () => componentKey.value++)
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars/colors';
@import '@/assets/scss/vars/breakpoints';
@import '@/assets/scss/mixins/media-breakpoints';
@import '@/assets/scss/functions/functions';

.posteingang-inbox__message-details {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.posteingang-inbox__message-details-messages {
	padding: 30px;
	overflow: hidden;
	&__intro__readstatus:before {
		content: ' ';
		width: 10px;
		height: 10px;
		background: $clr-C-T1;
		border-radius: 50%;
		display: inline-flex;
	}
	&__tools {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-end;
		align-items: center;
		align-content: stretch;

		i {
			font-size: px2rem(24);
			margin-left: 30px;
		}

		&__item {
			display: block;
			flex-grow: 0;
			flex-shrink: 1;
			flex-basis: auto;
			align-self: auto;
			order: 0;
			font-size: px2rem(24);
			margin-right: 20px;
			text-align: left;
			cursor: pointer;

			&__last-edited {
				flex-grow: 1;
				font-size: 10px;
				color: $clr-brand-dark-grey;
				&:hover {
					cursor: pointer;
				}
			}

			&:last-child {
				margin-right: 0;
			}

			&--colored {
				color: $clr-brand-blue;
			}

			&__back-to-list {
				margin-right: 0;

				@include media-up(md) {
					display: none;
				}
			}
		}

		& .back-to-list {
			margin-left: 0;
			margin-right: 20px;

			@include media-up(md) {
				display: none;
			}
		}
	}

	&__intro {
		width: 100%;
		display: flex;
		font-weight: 600;
		padding-top: 30px;

		&__readstatus {
			margin-right: 10px;
		}
		&__zusatzinfo:before {
			content: '|';
			display: inline-block;
			padding: 0 5px;
		}
	}

	&__date {
		margin-top: 5px;
		font-size: px2rem(12);

		.fal {
			margin-left: 5px;
		}
	}

	&__message {
		margin-top: 20px;
	}

	&__member {
		margin-bottom: 25px;
		display: flex;
		&-inner {
			line-height: px2rem(14);
			padding: 3px 6px;
			border-radius: 5px;
			color: $clr-brand-darkest-blue;
			font-size: px2rem(12);
			background-color: $clr-brand-light-blue-2;
		}
	}

	.fa-trash-alt {
		cursor: pointer;
	}
}
.posteingang-inbox__message-details-no-messages {
	display: inline-flex;
	justify-content: center;
	padding-top: 150px;
	width: 100%;
	height: 100%;
	img {
		width: 350px;
	}
}
</style>
