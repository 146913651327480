import { defineStore } from 'pinia'
import * as api from '@/services'
import { dispatchEvent } from '@/helper/polyfill'
import { MainMember } from '@/models/MemberStructure/MainMember'
import { SubMember } from '@/models/MemberStructure/SubMember'
import {
	MemberStructure,
	MemberStructureMember, MemberStructurePreferences
} from '@/types/memberStructure/memberStructure'
import {ref} from 'vue'
import {BaseMember} from '@/models/MemberStructure/BaseMember'

export const useMemberStructureStore = defineStore('memberStructure', () => {

	// state:
	const lastUpdateTime = ref<string>('')
	const memberStructure = ref<Array<BaseMember>>([])
	const isFetchingData = ref<boolean>(false)
	const savedMembers = ref<Array<string>>([])
	const selectedMembers = ref<Array<BaseMember>>([])
	const allMembers = ref<Array<string>>([])
	const messageUnreadCountAll = ref<number>(0)

	function getMemberStructure(id: string | undefined) {
		if (id === undefined) {
			return Promise.resolve()
		}
		isFetchingData.value = true
		return api.getMemberStructure(id).then(fetchedMemberStructure => {
			if (fetchedMemberStructure) {
				getMemberStructureSuccess(fetchedMemberStructure)
				updateTags()
			}
		}).finally(() => {
			isFetchingData.value = false
			updateSelectedMembers()
		})
	}

	function updateBubbles(id: string) {
		api.getMemberStructure(id).then(responseMemberStructure => {
			if (responseMemberStructure) {
				getMemberStructureSuccess(responseMemberStructure)
				updateTags()
			}
		}).finally(() => {
			isFetchingData.value = false
			updateSelectedMembers()
		})
	}

	function getSavedMembers() {
		isFetchingData.value = true

		return api.getSelectedMembers()
			.then(selectedMembers => {
				getSavedMemberStructureSuccess(selectedMembers)
			})
			.catch(error => {
				console.error('ERROR', error)
				return error
			})
			.finally(() => {
				isFetchingData.value = false
			})
	}

	function updateSelectedMembers() {
		if (selectedMembers.value.length > 0) {
			const selectedMemberIds = selectedMembers.value.map(item => item.memberId)
			selectedMembers.value = memberStructure.value
				.filter(member => selectedMemberIds.includes(member.memberId))
		} else {
			selectedMembers.value = []
		}
	}

	function updateTags() {
		if (selectedMembers.value.length > 0) {
			selectedMembers.value.forEach(item => {
				memberStructure.value.forEach(member => {
					if (item.memberId === member.memberId) {
						item.countUnread = member.countUnread
						updateReadStatus(item.memberId, item.countUnread)
					}
				})
			})
		}
	}

	function updateReadStatus(memberId: string, unreadCount: number) {
		// TODO: optimize array function
		selectedMembers.value.forEach(item => {
			if (item.memberId === memberId) {
				item.countUnread = unreadCount
			}
		})
	}

	function saveMembers(params: BaseMember[]) {
		const newParams = {
			mitgliedsnummern: params.map(param => {
				return param.memberId
			}),
		}

		return api.saveSelectedMembers(newParams).then(responseSavedMembers => {
			savedMembers.value = responseSavedMembers.mitgliedsnummern;
			return responseSavedMembers
		})
	}

	function getMessageUnreadCountAll(id: string) {
		return api.getMessageUnreadCountAll(id).then(responseUnreadCountAll => {
			setMessageUnreadCountAll(responseUnreadCountAll)
		}).catch(() => {
			setMessageUnreadCountAll(0)
		})

	}

	function setMessageUnreadCountAll(value: number) {
		messageUnreadCountAll.value = value
		window.unreadMessages = value
		dispatchEvent('unreadMessagesCountChanged')
	}

	function getMemberStructureSuccess(data: MemberStructure) {
		allMembers.value = []
		memberStructure.value = []
		lastUpdateTime.value = ''

		if (typeof data === 'object') {
			// @ts-ignore
			lastUpdateTime.value = data.lastUpdateTime
			if (data.mainMember?.memberId) {
				// @ts-ignore
				allMembers.value.push(data.mainMember?.memberId)
				// @ts-ignore
				memberStructure.value.push(new MainMember(data.mainMember))
			}

			if (data.subMembers && data.subMembers.length > 0) {
				data.subMembers.filter((member: MemberStructureMember) => {
					// @ts-ignore
					allMembers.value.push(member.memberId)
					// @ts-ignore
					memberStructure.value.push(new SubMember(member))
				})
			}
		}
	}

	function getSavedMemberStructureSuccess(data: MemberStructurePreferences | undefined) {
		// @ts-ignore
		if (data !== undefined && data.mitgliedsnummern.length > 0) {
			savedMembers.value = data.mitgliedsnummern.filter(memberId => (allMembers.value?.indexOf(memberId) ?? -1) !== -1)
		} else {
			savedMembers.value = []
		}
	}

	return {
		lastUpdateTime,
		memberStructure,
		isFetchingData,
		savedMembers,
		saveMembers,
		selectedMembers,
		allMembers,
		messageUnreadCountAll,
		getMemberStructure,
		getMemberStructureSuccess,
		getMessageUnreadCountAll,
		getSavedMembers,
		getSavedMemberStructureSuccess,
		updateBubbles
	}
})
