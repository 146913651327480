<template>
    <div :class="scsClass">
        <router-view />
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, onBeforeUnmount, version as vueVersion } from 'vue'
import { detectMobile } from '@/helper/detectmobile'

import { changeLocale } from '@/i18n'
import { useUserStore } from '@/stores/user'
import {storeToRefs} from 'pinia'

const scsName = import.meta.env.VITE_APP_PACKAGE_NAME
const errorContext = ref<null | ErrorContext>(null)
const userStore = useUserStore()
const { user } = storeToRefs(userStore)

if (!window.keycloak?.token) {
    checkTokenDelayed()
} else {
    checkToken()
}
initEventListeners()

onMounted(async() => {
    // not needed for testing local dev stuff
    /* c8 ignore start */
    if (import.meta.env.DEV) {
        console.log(`SCS ${import.meta.env.VITE_APP_PACKAGE_NAME}`)

        if (
            localStorage.getItem('axiosmock') &&
            (localStorage.getItem('axiosmock') !== 'false')
        ) {
            const modules = import.meta.glob('./services/*-mock.ts')
            for (const path in modules) {
                modules[path]().then(() => { })
            }
        }
    }

    if (localStorage.getItem('buildInfo') === 'true') {
		// @ts-ignore
		const pkgVersion = __PACKAGE_VERSION__
		// @ts-ignore
		const buildTimestamp = __BUILD_TIMESTAMP__
		console.log(
			`SCS Project: ${
				import.meta.env.VITE_APP_PACKAGE_NAME
			} | BuildVersion: ${pkgVersion} | VueVersion: ${vueVersion} | BuildTime: ${buildTimestamp}`,
		)
	}
    /* c8 ignore end */

    window.addEventListener('languageChanged', setLanguage, false)
    setLanguage()
})

onBeforeUnmount((): void => {
    window.removeEventListener('languageChanged', setLanguage)
})

const scsClass = computed((): string => (isMobile() ? `scs-${scsName} isMobile` : `scs-${scsName}`))

function setLanguage(): void {
    let clientLanguage = 'de_DE'

    if (document.cookie.indexOf('CAS_PREFERRED_LANGUAGE') !== -1) {
        let langCode = document.cookie.split('CAS_PREFERRED_LANGUAGE=')[1].split(';')[0]
        if (langCode && langCode.length && /^[a-zA-Z_]{2,5}$/.test(langCode)) {
            clientLanguage = langCode
        }
    }

    window.cp = window.cp || {}
    window.cp.language = clientLanguage.match(/([\w]{2})$/g)![0].toUpperCase()

    changeLocale(clientLanguage)
    userStore.onLanguageChange(clientLanguage)
}

async function checkToken(): Promise<boolean> {
    if (!window.keycloak?.token) {
        return false
    }
    userStore.onValidTokenExists(window.keycloak)
    userStore.getCbraUser()

    return true
}

function checkTokenDelayed(timeout = 100): void {
    setTimeout(async() => {
        const hasToken = await checkToken()
        const newTimeout = timeout * 2
        if (
            !hasToken &&
            (newTimeout <= 30000)
        ) {
            checkTokenDelayed(timeout * 2)
        }
    }, timeout)
}

function initEventListeners(): void {
    window.addEventListener('loggedIn', () => {
        // Authenticate the user within this microfrontend
        // when a login event was dispatched from another microfrontend
        console.log('loggedIn')
        checkToken()
    }, false)

    window.addEventListener('actForMemberChanged', () => {
        let actForMemberId = sessionStorage.getItem('actForMemberId')
        let actForMemberName = sessionStorage.getItem('actForMemberName')

        if (!actForMemberId || !actForMemberName || actForMemberId === user.value.cr_membernumber) {
            userStore.setActForMember( {
                actForMemberId: undefined,
                actForMemberName: ''
            })
        } else {
            userStore.setActForMember({
                actForMemberId: actForMemberId,
                actForMemberName: actForMemberName
            })
        }
    }, false)

    window.addEventListener('keycloakTokenUpdated', () => {
        // Update the state and the request interceptor
        // on keycloak token updates
        console.log('keycloakTokenUpdated')
        userStore.onKeycloakTokenUpdateSuccess(window.keycloak)
    }, false)
}

function clearError(): void {
    errorContext.value = null
}

function isMobile(width?: number): boolean {
	return width ? detectMobile(width) : detectMobile(768)
}

// todo: remove workaround as soon as possible:
window.history.pushState = new Proxy(window.history.pushState, {
    apply: (target, thisArg, argArray: [data: any, unused: string, url?: string | URL | null | undefined]) => {
        // used to fix a bug with multiple routers https://github.com/single-spa/single-spa/issues/1030
        // will probably be obsolete once everyone uses the same router instance.
        if (argArray[0].current) {
            return target.apply(thisArg, argArray)
        }
    },
})

defineExpose({ checkToken, clearError })
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars/colors';
@import '@/assets/scss/vars/breakpoints';
@import '@/assets/scss/mixins/media-breakpoints';
@import '@/assets/scss/functions/functions';

/* Add alpha channel as defined in new styleguide version (copared to Crefo UI) */
.scs-posteingang :deep(.bg-brand-light-grey) {
    background: $clr-sand !important;
    background-color: $clr-sand !important;
}

.scs-posteingang :deep(.custom-select .v-select) {
    background: $white;
}

.scs-posteingang :deep(.custom-select .vs__dropdown-toggle) {
    border-radius: 0;
    border-color: #b5b5b5;
    padding: 1rem 1.2rem;
    line-height: 24px;
    max-height: 56px;
}

.scs-posteingang :deep(.custom-select .vs__selected) {
    margin: 0;
    color: $clr-gray-3;
    padding: 0;
}

.scs-posteingang :deep(.custom-select .vs__selected-options) {
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    width: fit-content;
    white-space: nowrap;
}

.scs-posteingang :deep(.custom-select .vs__search),
.scs-posteingang :deep(.custom-select .vs__search:focus) {
    margin-top: 0;
}

.scs-posteingang :deep(.custom-select .vs__actions) {
    padding: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    right: 1.2rem;
}

.scs-posteingang :deep(.custom-select .vs__actions:after) {
    content: '\e909';
    font-family: crefo-ui-icons, sans-serif !important;
    font-size: 24px;
    display: block;
    position: absolute;
    color: #b5b5b5;
    transform: rotate(0);
    transition: all 0.15s ease-in-out;
}

.scs-posteingang :deep(.custom-select .vs__actions:hover:after) {
    color: $clr-brand-blue;
}

.scs-posteingang :deep(.custom-select .vs__dropdown-toggle:hover .vs__actions:after) {
    color: $clr-brand-blue;
}

.scs-posteingang :deep(.custom-select .vs__dropdown-toggle:hover),
.scs-posteingang :deep(.custom-select .vs--open .vs__dropdown-toggle) {
    border-color: $clr-brand-blue;
}

.scs-posteingang :deep(.custom-select .vs--open .vs__actions:after) {
    transform: rotate(180deg);
}

.scs-posteingang :deep(.custom-select .vs__dropdown-option) {
    white-space: normal;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
    padding: 1rem 1.2rem;
}

.scs-posteingang :deep(.custom-select .vs__dropdown-option:last-child) {
    border: 0;
}

.scs-posteingang :deep(.custom-select .vs__dropdown-menu) {
    padding-bottom: 0;
    padding-top: 0;
    border-radius: 0;
    border: 1px solid $clr-brand-blue;
    border-top: 1px solid #ccc;
    box-shadow: none;
}

.scs-posteingang :deep(.custom-select .vs__dropdown-option--selected) {
    background: $clr-brand-blue;
    color: #fff;
}
.scs-posteingang :deep(.custom-select .vs__dropdown-option--highlight) {
    background: $clr-brand-blue;
}

.scs-posteingang :deep(.custom-select){
    margin-bottom: 15px;
}

.scs-posteingang :deep(.error-msg) {
    color: #e05469;
    line-height: 20px;
    font-size: 14px;
    padding-top: 10px;
    margin-bottom: 0;
}

.scs-posteingang :deep(.has-error .vs__dropdown-toggle) {
    border-color: #e05469;
}

.scs-posteingang :deep(.help-text) {
    font-size: 12px;
    color: $clr-gray-2;
    margin-top: 5px;
}

.scs-posteingang :deep(.has-error .crefo-datepicker .mx-input-wrapper) {
    border-color: $clr-brand-red;
}

.scs-posteingang :deep(.crefo-datepicker) {
    width: 100%;
}

.scs-posteingang :deep(.crefo-datepicker .mx-input-wrapper) {
    color: $clr-gray-2;
    border-width: 1px;
    border-color: $clr-gray;
    border-style: solid;
    flex: 1 1 auto;
    position: relative;
    transition: border-color 0.15s ease-in-out;
}

.scs-posteingang :deep(.crefo-datepicker .mx-input-wrapper input.mx-input) {
    flex: 1 1 auto;
    font-size: 15px;
    font-weight: normal;
    line-height: 24px;
    padding: 1rem 3.2rem 1rem 1.2rem;
    outline: none;
    height: auto;
    max-width: 100%;
    min-width: 0;
    width: 100%;
    border-style: none;
    color: $clr-gray-3;
    border-color: $clr-gray;
    background: $white;
    box-shadow: none;
}

.scs-posteingang :deep(.crefo-datepicker .mx-input-wrapper .mx-icon-calendar),
.scs-posteingang :deep(.crefo-datepicker .mx-input-wrapper .mx-icon-clear) {
    right: 1.2rem;
    color: $clr-gray-2;
}

.scs-posteingang :deep(.crefo-datepicker .mx-input-wrapper:hover) {
    border-color: $clr-C-P1;
}

.scs-posteingang :deep(.crefo-datepicker .mx-input-wrapper:hover .mx-icon-calendar),
.scs-posteingang :deep(.crefo-datepicker .mx-input-wrapper:hover .mx-icon-clear) {
    color: $clr-gray-2;
}

.scs-posteingang :deep(.crefo-datepicker.disabled .mx-input-wrapper:hover) {
    border-color: $clr-gray;
}

.scs-posteingang :deep(.mx-datepicker-popup) {
    margin-top: -1px;
    box-shadow: none !important;
}

.scs-posteingang :deep(.mx-datepicker-main) {
    border-width: 1px;
    border-color: $clr-gray;
    border-style: solid;
    transition: border-color 0.15s ease-in-out;
    color: $clr-gray-3 !important;
    font-size: 15px !important;
    line-height: 26px !important;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif !important;
}

.scs-posteingang :deep(.mx-datepicker-main:hover) {
    border-color: $clr-C-P1;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-btn) {
    color: $white;
    background-color: $clr-C-P1;
    line-height: 56px;
    font-size: 15px;
    font-weight: 600;
    padding: 0 13.5px;
    margin: 0;
    border: none;
    border-radius: 0;
    white-space: normal;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-btn:hover) {
    background-color: $clr-C-S2;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-time) {
    width: 360px;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-time .mx-time-header) {
    height: 70px;
    padding-top: 6px;
    border-bottom-color: $clr-gray;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-time .mx-time-header .mx-btn) {
    text-align: center;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-time .mx-time-content) {
    height: 239px;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-time .mx-time-content .mx-time-column) {
    border-left-color: $clr-gray;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-time .mx-time-content .mx-time-column li) {
    padding: 8px 0;
    height: 31px;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-time .mx-time-content .mx-time-column .mx-time-item) {
    font-size: 15px;
    line-height: 15px;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-time .mx-time-content .mx-time-column .mx-time-item.active),
.scs-posteingang :deep(.mx-datepicker-main .mx-time .mx-time-content .mx-time-column .mx-time-item:hover) {
    background-color: transparent;
    color: $clr-C-P1;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-time .mx-time-content .mx-time-column .mx-time-item.active) {
    font-weight: 700;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar) {
    width: 360px;
    padding: 15px 18px;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar + .mx-calendar) {
    border-left-color: $clr-gray;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-header) {
    margin-bottom: 15px;
}

@media only screen and (max-width: 1199px) {
    .scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-header) {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 768px) {
    .scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-header) {
        margin-bottom: 7.5px;
    }
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-header .mx-btn) {
    min-width: 32px;
    min-height: 32px;
    color: $clr-gray-3;
    background-color: transparent;
    line-height: 32px;
    font-weight: normal;
    white-space: nowrap;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-header .mx-btn:hover) {
    color: $clr-C-P1;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-header .mx-calendar-header-label) {
    font-size: 15px;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content) {
    height: 230px;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .mx-table th),
.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content td) {
    font-size: 15px;
    line-height: 15px;
    font-weight: normal;
    text-align: center;
    transition: color 0.1s ease-in-out;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .mx-table th) {
    font-weight: 600;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .mx-week-number) {
    opacity: 0.6;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .mx-table-date .today) {
    color: $clr-C-P1;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .mx-table-date .cell.active),
.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .mx-table-date .cell:hover),
.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .mx-table-month .cell.active),
.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .mx-table-month .cell:hover),
.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .mx-table-year .cell.active),
.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .mx-table-year .cell:hover) {
    background-color: transparent;
    color: $clr-C-P1;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .mx-table-date .cell.active),
.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .mx-table-month .cell.active),
.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .mx-table-year .cell.active) {
    font-weight: 700;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .mx-table-year) {
    padding: 0 30px;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .not-current-month) {
    color: $clr-gray;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .not-current-month:hover) {
    color: $clr-gray;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .cell:hover) {
    background-color: transparent;
    color: $clr-C-P1;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .cell.disabled) {
    color: $white;
    background-color: $clr-gray;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar .mx-calendar-content .cell.disabled:hover) {
    color: $white;
    background-color: $clr-gray;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar.mx-calendar-week-mode .mx-date-row) {
    transition: background-color 0.1s ease-in-out;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar.mx-calendar-week-mode .mx-date-row:hover) {
    background-color: rgba(0, 157, 224, 0.05);
}

.scs-posteingang :deep(.mx-datepicker-main .mx-calendar.mx-calendar-week-mode .mx-date-row:hover td) {
    color: $clr-C-P1;
}

.scs-posteingang :deep(.mx-datepicker-main .mx-datepicker-footer) {
    text-align: center;
    border-top-color: $clr-gray;
}

.scs-posteingang :deep(.posteingang-inbox__message-details-messages__message__change-list) {
    list-style-position: outside;
    padding-left: 20px;
    li {
        margin-top: 10px;
    }
}


.scs-posteingang :deep(.has-error) {
    .crefo-datepicker .mx-input-wrapper,
    .crefo-datepicker .mx-input-wrapper input.mx-input,
    .error-msg {
        color: $clr-brand-red;
        border-color: $clr-brand-red;
    }
}

.scs-posteingang :deep(.tag-input) {
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
}

.scs-posteingang :deep(.tag-input__tag) {
    font-size: 16px;
    margin-right: 10px;
    margin-top: 10px;
    padding: 10px 10px;
    border-radius: 5px;
    background-color: $clr-brand-light-blue-2;
    display: inline-flex;
    align-items: center;
    flex-direction: row;

    svg {
        margin-left: 10px;
        cursor: pointer;
    }

    .cursor-pointer {
        cursor: pointer;
    }
}

.scs-posteingang :deep(.tag-input__tag > span) {
    cursor: pointer;
    opacity: 0.75;
}

.scs-posteingang :deep(.icon-color-blue) {
    color: #009fe3;
}

.scs-posteingang :deep(.unread-bubble) {
    background-color: $clr-brand-purple;
    color: $white;
    min-width: 24px;
    height: 24px;
    border-radius: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;

    span {
        line-height: 24px;
        padding: 5px;
        font-size: px2rem(12);
    }
}

.scs-posteingang :deep(.posteingang-inbox__message-details-messages__message-greetings) {
    padding-top: 30px;
}
</style>
