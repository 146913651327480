<template>
	<div>
		<p>Dear Sir or Madam,</p>

		<p>
			updated information about your order {{ templateVars.productOrderId }} on the company
			{{ templateVars.mergedReportData }} has become available:
		</p>

		<p>
			<strong>{{ templateVars.statusReplyReason }}</strong>
		</p>

		<p>
			Please click
			<crefo-message-link
				:msg-link="templateVars.transactionLink"
				:msg-text="'here'"
			/>
			to display the status response.
		</p>

		<p>With kind regards</p>

		<p>{{ templateVars.vcName }}</p>
	</div>
</template>

<script lang="ts">
import CrefoMessageLink from '@/components/atoms/CrefoMessageLink/index.vue'

export default {
	components: {
		CrefoMessageLink,
	},
	props: {
		templateVars: {
			type: Object,
			default: null,
		},
	},
}
</script>
