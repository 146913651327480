<template>
	<div>
		<component
			:is="components[locale as keyof ContainerOfAllLocales]"
			:template-vars="getNachricht"
		/>
	</div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, shallowRef } from 'vue'

import de_DE from '../templates/de_DE/OrderStatusReplyTemplate.vue'
import de_AT from '../templates/de_DE/OrderStatusReplyTemplate.vue'
import en_US from '../templates/en_US/OrderStatusReplyTemplate.vue'
import fr_FR from '../templates/fr_FR/OrderStatusReplyTemplate.vue'
import fr_LU from '../templates/fr_FR/OrderStatusReplyTemplate.vue'
import {ContainerOfAllLocales} from '@/types/ContainerOfAllLocales'
import { Auskunft, getMergedReportData } from '@/models/Messages/Auskunft'

const { locale, t, te } = useI18n()

const components = shallowRef<ContainerOfAllLocales>({
	de_DE,
	de_AT,
	en_US,
	fr_FR,
	fr_LU,
})

const props = defineProps<{
	nachricht?: Auskunft
	vc?: { name: string},
}>()

const getNachricht = computed(() => {
	return {
		productOrderId: props.nachricht?.attributes?.productOrderId,
		mergedReportData: getMergedReportData(props.nachricht?.attributes, locale.value),
		statusReplyReason: getStatusReplyReason(props.nachricht?.attributes?.statusReplyReasonId),
		transactionLink: `${t('link.vorgangsdetailseite')}?no_cache=1&transactionId=${props.nachricht?.attributes?.transactionId}`,
		vcName: props.vc?.name,
	}
})

function getStatusReplyReason(item: string | undefined) {
    if (item === undefined) {
       return undefined
    }
    console.log(te(`statusReplyReason.${item}`))
    return te(`statusReplyReason.${item}`) ? t(`statusReplyReason.${item}`) : t('terminationReason.A99')
}
</script>
