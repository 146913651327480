<script setup lang="ts">

import {computed} from 'vue'
import {BaseMember} from '@/models/MemberStructure/BaseMember'

const props = defineProps<{
    modelValue: Array<BaseMember>
    member: BaseMember
    noBorder: Boolean
}>();

const emit = defineEmits(["update:modelValue"])
const model = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit("update:modelValue", value)
    },
});
</script>

<template>
	<li
		data-qa="memberstructure-list-item"
		class="memberstructure--list-item"
		:class="{ noBorder: noBorder }"
	>
		<font-awesome-icon
			icon="fal fa-building"
			class="fa-lg memberstructure--list-item--building-icon icon-color-blue"
		/>
		<div class="memberstructure--list-item--details">
			<div class="text-semibold text-brand-darkest-blue">
				<span data-qa="memberstructure-membername-memberid">
					{{ member.memberName }} ({{ member.memberId }})
				</span>
				<div
					v-if="member.countUnread > 0"
					class="unread-bubble"
				>
					<span data-qa="memberstructure-unread-count">{{ member.countUnread }}</span>
				</div>
			</div>
			<div
				class="text-small"
				data-qa="memberstructure-companyAddress"
			>
				{{ member.address }}
			</div>
		</div>
		<div class="crefo-toggle-switch memberstructure--list-item--toggle-switch">
			<label>
				<input
					v-model="model"
					:value="member"
					type="checkbox"
					data-qa="memberstructure-select-item"
				/>
				<span class="knob" />
			</label>
		</div>
	</li>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/vars/colors.scss';
@import '@/assets/scss/functions/px2rem';

.memberstructure {
	&--list-item {
		&--building-icon {
			display: flex;
			border-radius: 50px;
			border: 1px solid $clr-brand-light-grey-2;
			padding: 15px 15px;
			width: 20px;
			height: 20px;
			justify-content: center;
			align-items: center;
		}

		&--details {
			margin-left: 15px;
			display: flex;
			justify-content: center;
			flex-flow: column;
		}

		&--toggle-switch {
			margin-left: auto;
			margin-right: 15px;
			margin-bottom: 0;
			display: inline-block;

			label {
				margin-bottom: 0;
				display: inline-flex;
			}
		}
	}
}
</style>
