<template>
	<div>
		<p>Mesdames et Messieurs,</p>

		<p>
			nous tenons à vous informer de l’état actuel de votre commande {{ templateVars.productOrderId }} sur la
			société {{ templateVars.mergedReportData }}:
		</p>

		<p>
			<strong>{{ templateVars.statusReplyReason }}</strong>
		</p>

		<p>
			Veuillez cliquer
			<crefo-message-link
				:msg-link="templateVars.transactionLink"
				:msg-text="'l’état de la commande en cliquant'"
			/>.
		</p>

		<p>Cordialement</p>

		<p>Votre {{ templateVars.vcName }}</p>
	</div>
</template>

<script lang="ts">
import CrefoMessageLink from '@/components/atoms/CrefoMessageLink/index.vue'

export default {
	components: {
		CrefoMessageLink,
	},
	props: {
		templateVars: {
			type: Object,
			default: null,
		},
	},
}
</script>
