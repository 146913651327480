// @ts-ignore
import { createI18n } from 'vue-i18n'
import { deepCopy } from '@/helper/deepCopy'

const i18n = createI18n({
	locale: 'de_DE',
	fallbackLocale: 'de_DE',
	messages: loadLocaleMessages(),
	legacy: false,
	silentTranslationWarn: true,
    warnHtmlMessage: false,
	flatJson: true,
})

function loadLocaleMessages() {
	const locales = import.meta.glob('./i18n/*.json', { eager: true })
	const messages = {}
	for (const locale in locales) {
		const localeModule = locales[locale]
		// @ts-ignore
		messages[localeModule.locale] = deepCopy(localeModule.default)
	}
	return messages
}

export default i18n

export function changeLocale(locale: string) {
    i18n.global.locale.value = locale
}
