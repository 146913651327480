<template>
	<!-- TODO: rename data-qa -->
	<div
		v-if="getBubbleText"
		class="message-list-bubble"
		data-qa="posteingang-message-list-third-line__member"
		@mouseover="tooltipIsVisible = true"
		@mouseleave="tooltipIsVisible = false"
	>
		<span ref="tooltipAnker">{{ bubbleTextStripped }}</span>
	</div>

	<CrefoTooltip
		v-if="tooltipIsVisible && tooltipIsNeeded"
		:anker-element="tooltipAnker"
	>
		{{ getBubbleText() }}
	</CrefoTooltip>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import CrefoTooltip from '@/components/atoms/CrefoTooltip/index.vue'

const props = defineProps({
	payload: {
		type: Object,
		default: () => {},
	},
})

const tooltipThreshold = ref(55)
const tooltipIsVisible = ref(false)
const tooltipAnker = ref()
const tooltipIsNeeded = computed(() => (getBubbleText().length > tooltipThreshold.value))

const bubbleTextStripped = computed(() => {
	if (getBubbleText().length > tooltipThreshold.value) {
		return truncateLeft(getBubbleText())
	} else {
		return getBubbleText()
	}
})

function getBubbleText() {
	let text = ''
	let id = props.payload?.nachrichtType === 'INKASSO' ? props.payload?.memberId : props.payload?.memberUserId

	if (props.payload?.surName && props.payload?.givenName) {
		text = `${props.payload?.surName}, ${props.payload?.givenName} | ${id}`
	} else if (props.payload?.surName && props.payload?.givenName === null) {
		text = `${props.payload?.surName} | ${id}`
	} else {
		text = `${id}`
	}

	return `${text}`
}

function truncateLeft(text: string) {
	let splitString = ` | ${props.payload.nachrichtType === 'INKASSO' ? props.payload.memberId : props.payload.memberUserId}`
	let splittedText = text.split(splitString)
	let foo = text.length - tooltipThreshold.value
	let newTeil1 = truncate(splittedText[0], splittedText[0].length - foo)
	return newTeil1 + splitString
}

function truncate(text: string, stop: number, claim: string = '...') {
	stop = stop - claim.length
	return text.slice(0, stop) + (stop < text.length ? '...' : '')
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars/colors';
@import '@/assets/scss/functions/functions';

.message-list-bubble {
	line-height: px2rem(14);
	padding: 3px 6px;
	border-radius: 5px;
	color: $clr-brand-darkest-blue;
	font-size: px2rem(12);
	background-color: $clr-brand-light-blue-2;
}
</style>
