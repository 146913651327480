<template>
	<div
		v-click-outside="closeList"
		class="pennant-picker"
		data-qa="pennant-picker__list-item__category-picker"
		@click="isOpen = !isOpen"
	>
		<font-awesome-icon
			icon="fa-solid fa-pennant"
			:class="'pennant-picker__list-item__icon-color--' + getCategory?.kategorie"
			data-qa="pennant-picker__list-item__icon__active-category--color"
		/>

		<ul
			class="pennant-picker__list"
			:class="{
				'pennant-picker__list--is-open': isOpen,
			}"
			data-qa="pennant-picker__list"
		>
			<li
				v-for="item in listOfItems"
				:key="item.name"
				class="pennant-picker__list-item"
				:class="{
					'pennant-picker__list-item--active': activeItem === item,
				}"
				data-qa="pennant-picker__list-item__category-picker__list-item"
				@click="handleClickOnItem(item)"
			>
				<font-awesome-icon
					icon="fa-solid fa-pennant"
					:class="'pennant-picker__list-item__icon-color--' + item.kategorie"
					data-qa="pennant-picker__list-item__icon__category--color"
				/>
				<span>{{ item.name }}</span>
			</li>
		</ul>
	</div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useMessagesStore } from '@/stores/messages'
import { useI18n } from 'vue-i18n'
import {Kategorie} from '@/types/messageBox/MessageSearch'
import {Base} from '@/models/Messages/Base'

const messagesStore = useMessagesStore()
const { t } = useI18n()

const props = defineProps<{
	selectedNachricht: Base
}>()

const isOpen = ref(false)
const listOfItems = ref<{ name: string; kategorie: Kategorie }[]>([
	{
		name: t('Posteingang.PennantPicker.KEINE_KATEGORIE'),
		kategorie: 'KEINE_KATEGORIE',
	},
	{
		name: t('Posteingang.PennantPicker.KATEGORIE_1'),
		kategorie: 'KATEGORIE_1',
	},
	{
		name: t('Posteingang.PennantPicker.KATEGORIE_2'),
		kategorie: 'KATEGORIE_2',
	},
	{
		name: t('Posteingang.PennantPicker.KATEGORIE_3'),
		kategorie: 'KATEGORIE_3',
	},
	{
		name: t('Posteingang.PennantPicker.KATEGORIE_4'),
		kategorie: 'KATEGORIE_4',
	},
])

const activeItem = ref()

const getCategory = computed(() => {
	return props.selectedNachricht
		? listOfItems.value.find((item) => item.kategorie === props.selectedNachricht.kategorie)
		: listOfItems.value[0]
})

function handleClickOnItem(item: { name: string; kategorie: Kategorie }) {
	activeItem.value = item
	messagesStore.setNachrichtKategorie(props.selectedNachricht.nachrichtId, item.kategorie)
}
function closeList() {
	isOpen.value = false
}
</script>

<style lang="scss" scoped>
@import './category-color.scss';

.crefo-tooltip {
	min-width: 300px;
}

.pennant-picker {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;
	cursor: pointer;

	&__list {
		position: absolute;
		top: calc(100% + 5px);
		background-color: white;
		list-style: none;
		transition: transform 0.1s;
		transform-origin: top;
		transform: scaleY(0);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		border: 1px solid $clr-brand-light-grey-2;
		padding: 0;
		overflow-x: hidden;

		&-item {
			display: flex;
			align-items: center;
			padding: 5px 10px;
			width: 100%;

			&:hover,
			&:focus {
				background-color: $clr-brand-blue;

				span {
					color: white;
				}
			}

			&--active {
				background-color: $clr-brand-light-blue-1;
			}

			span {
				margin-left: 10px;
				font-size: 14px;
				color: $clr-brand-darkest-grey;
				white-space: nowrap;
			}

			&__icon-color {
				@include category-color;
			}
		}

		&--is-open {
			transform: scaleY(1);
		}
	}
}
</style>
