<template>
	<div
		v-if="hasMoreThanOneMemberInStructure"
		class="row mb"
	>
		<div class="col-12">
			<ul
				class="memberstructure"
				data-qa="show-memberstructure"
			>
				<li :class="{ active: isOpen }">
					<div
						class="opener"
						data-qa="memberstructure-slide-opener"
						@click="openMemberstructure"
					>
						<div class="memberstructure--icon-headline">
							<font-awesome-icon
								icon="fal fa-building"
								class="fa-lg icon-color-blue"
							/>

							<span class="text-brand-darkest-blue ml">{{ $t('members.headline') }}</span>
						</div>
						<div class="memberstructure--more-members">
							<span data-qa="memberstructure-more-members-text">{{ showMore }}</span>
							<i
								class="crefo-ui-icon"
								:class="{
									'icon-close': isOpen,
									'icon-collapse-open': !isOpen,
								}"
								aria-hidden="true"
							/>
						</div>
					</div>
					<div
						class="slide"
						:data-qa="isOpen ? 'memberstructure-slide-open' : 'memberstructure-slide-close'"
					>
						<div class="slide-content">
							<div class="slide-container">
								<div class="saveArea mb-small">
									<span
										data-qa="memberStructureSaveButton"
										@click="handleSaveMembersClick"
										>{{ $t('auswahlSpeichern') }}
										<font-awesome-icon
											class="btn-icon-save"
											icon="fa-light fa-floppy-disk"
										/>
									</span>
								</div>
								<div class="searchArea">
									<form class="searchForm">
										<i
											class="crefo-ui-icon icon-magnifier"
											aria-hidden="true"
										/>
										<input
											v-model="memberStructureSearch"
											type="text"
											data-no-crefo-ui="true"
											data-qa="memberstructure-search-input"
											:placeholder="memberstructureSearchPlaceholder"
										/>

										<font-awesome-icon
											v-show="memberStructureSearch.length > 0"
											class="btn-icon-delete cursor-pointer"
											icon="fa-times fa-lg"
											aria-hidden="true"
											data-qa="memberstructure-delete-search"
											@click="deleteSearch()"
										/>
									</form>
									<div
										v-show="!memberStructureSearch.length"
										class="crefo-toggle-switch"
									>
										<span v-if="!isSelectAll">{{ $t('members.activateAll') }}</span>
										<span v-else>{{ $t('members.deactivateAll') }}</span>
										<label class="mr">
											<input
												type="checkbox"
												:value="isSelectAll"
												:checked="isSelectAll"
												data-qa="memberstructure-select-all"
												@click="selectAll()"
											/>
											<span class="knob" />
										</label>
									</div>
								</div>
							</div>
							<div
								class="slide-container bordertb"
								style="max-height: 500px; overflow: auto"
							>
								<ul data-qa="memberStructure-list">
									<template
										v-for="(item, index) in filteredMemberstructure"
										:key="item.memberId"
									>
										<ListItem
											:member="item"
											v-model="selectedMembers"
											:noBorder="filteredMemberstructure.length === index + 1"
										/>
									</template>
								</ul>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>

		<div class="col-12">
			<div
				v-show="selectedMembers.length > 0"
				class="tag-input"
			>
				<template
					v-for="(tag, tagIndex) in selectedMembers"
					:key="tag.memberId"
				>
					<TagItem
						:tag="tag"
            :index="tagIndex"
						@remove-tag="removeTag"
					/>
				</template>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import ListItem from '@/components/molecules/MemberStructure/ListItem/index.vue'
import TagItem from '@/components/molecules/MemberStructure/TagItem/index.vue'

import { onMounted, computed, ref } from 'vue'

import { useI18n } from 'vue-i18n'
import { useMemberStructureStore } from '@/stores/memberStructure'
import { useMessagesStore } from '@/stores/messages'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import {BaseMember} from '@/models/MemberStructure/BaseMember'

const { t } = useI18n()

const memberStructureStore = useMemberStructureStore()
const messagesStore = useMessagesStore()
const userStore = useUserStore()
const { authenticated, user } = storeToRefs(userStore)

const { memberStructure, selectedMembers, savedMembers } = storeToRefs(memberStructureStore)
const { memberStructureLoaded } = storeToRefs(messagesStore)

const memberStructureSearch = ref('')
const isOpen = ref(false)

const showMore = computed(() => {
	return isOpen.value ? t('close') : t('members.show.more')
})

const hasMoreThanOneMemberInStructure = computed(() => {
	return memberStructure.value.length > 1
})

const memberstructureSearchPlaceholder = computed(() => {
	return t('members.search')
})

const isSelectAll = computed(() => {
	return selectedMembers.value.length === memberStructure.value.length
})

const filteredMemberstructure = computed(() => {
	let results = memberStructure.value
	if (memberStructureSearch.value.length) {
		results = memberStructure.value.filter(entry => {
			return (
				entry.memberName.toLowerCase().indexOf(memberStructureSearch.value.toLowerCase()) !== -1 ||
				entry.address.toLowerCase().indexOf(memberStructureSearch.value.toLowerCase()) !== -1 ||
				entry.memberId.toLowerCase().indexOf(memberStructureSearch.value.toLowerCase()) !== -1
			)
		})
	}
	return results
})

async function handleSaveMembersClick() {
	memberStructureStore.saveMembers(selectedMembers.value).then(() => {
      window.CrefoUI.Modal.show({
          title: t('MemberStructure.Save.Modal.Success.Title'),
          content: t('MemberStructure.Save.Modal.Success.Content'),
          buttons: {
              confirm: t('MemberStructure.Save.Modal.Success.Btn.Text'),
          },
          icon: 'success',
          dataQa: 'saving-success',
      })
  }).catch(() => {
      window.CrefoUI.Modal.show({
          title: t('MemberStructure.Save.Modal.Failure.Title'),
          content: t('MemberStructure.Save.Modal.Failure.Content'),
          buttons: {
              confirm: t('MemberStructure.Save.Modal.Failure.Btn.Text'),
          },
          icon: 'error',
          dataQa: 'saving-failure',
      })
  })
}

function selectAll() {
	let _selectedMembers: any = []
	if (!isSelectAll.value) {
		for (let i in memberStructure.value) {
			_selectedMembers.push(memberStructure.value[i])
		}
	}

	updateSelectedMembers(_selectedMembers)
}

function deleteSearch() {
	memberStructureSearch.value = ''
}

function openMemberstructure() {
	isOpen.value = !isOpen.value
}

function removeTag(selectedMemberIndex: number) {
	updateSelectedMembers(selectedMembers.value.toSpliced(selectedMemberIndex, 1))
}

function updateSelectedMembers(selectedMembersValue: BaseMember[]) {
	selectedMembers.value = selectedMembersValue
}

async function fetchData() {
	// @ts-ignore TODO: check when switching to typescript
	const memberStructurePromise = memberStructureStore.getMemberStructure(user.value.cr_membernumber)
      // savedMembers after memberStructure because of race conditions:
      .then(() => memberStructureStore.getSavedMembers())
	// const savedMembersPromise = memberStructureStore.getSavedMembers()
	const unreadCountAllPromise = memberStructureStore.getMessageUnreadCountAll(user.value.cr_membernumber!)

	await Promise.all([memberStructurePromise, unreadCountAllPromise]).then(() => {
		memberStructureLoaded.value = true
		updateNewSavedMembers()
		// reset saved members after setting it once for initial load
		// memberStructureStore.getSavedMemberStructureSuccess(undefined)
	})
	// let res=  { result, result2, result3 }

	// memberStructureLoaded.value = true
	// updateNewSavedMembers()
	// // reset saved members after setting it once for inital load
	// memberStructureStore.getSavedMemberStructureSuccess([])
}

function updateNewSavedMembers() {
	if (savedMembers.value.length > 0) {
		const tempSelectedMembers = memberStructure.value
        .filter(member => savedMembers.value.includes(member.memberId))
		updateSelectedMembers(tempSelectedMembers)
	}
}

onMounted(() => {
	if (authenticated.value) {
		fetchData()
	} else {
		userStore.$subscribe((mutation, state) => {
			if (state.authenticated) {
				fetchData()
			}
		})
	}
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars/colors.scss';

.crefo-ui-accordion {
	margin-bottom: 0;
}

.searchArea {
	display: flex;
	justify-content: space-between;

	.searchForm {
		width: 100%;
		display: flex;
		align-items: center;
		color: $clr-brand-blue;

		input {
			border: none;
			width: 100%;
			display: flex;
			flex: auto;
			padding: 1rem 1.2rem;
			outline: none;
			font-size: px2rem(16);
			line-height: px2rem(20);
		}
	}

	.crefo-toggle-switch {
		padding: 10px 0;
		display: flex;
		flex: none;
		align-items: center;
		margin-left: 20px;

		> span {
			margin-right: 10px;
		}

		label {
			margin-bottom: 0;
		}
	}
}

.memberstructure {
	position: relative;
	list-style-type: none;
	margin-left: 0;
	padding-left: 0;
	margin-bottom: 0;

	&--more-members {
		display: flex;
		align-items: center;
		font-size: px2rem(16);
		color: $clr-brand-blue;

		i {
			margin-left: 15px;
			font-size: px2rem(18);
		}
	}

	.slide-container {
		width: 100%;
	}

	.bordertb {
		border-top: 1px solid $clr-brand-light-grey-2;
		border-bottom: 1px solid $clr-brand-light-grey-2;
	}

	.saveArea {
		display: flex;
		justify-content: flex-end;
		margin-right: 15px;
		cursor: pointer;

		&:hover {
			color: $clr-brand-blue;
		}

		.btn-icon-save {
			width: 20px;
			height: 20px;
		}
	}

	li {
		border-bottom: 1px solid $clr-brand-light-grey-2;

		.opener {
			position: relative;
			cursor: pointer;
			padding: 30px 0;
			color: $clr-brand-dark-grey;
			font-family: 'Averta Std', 'Open Sans', Arial, Helvetica, sans-serif;
			font-weight: 600;
			display: flex;
			justify-content: space-between;
			letter-spacing: 0.5px;
			font-size: 20px;
			line-height: 30px;
			user-select: none;

			@media only screen and (max-width: 767px) {
				flex-direction: column;
			}
		}

		.slide {
			transform: scaleY(0);
			transform-origin: top;
			overflow: hidden;
			height: 0;
			opacity: 0;
			transition: all 0.3s ease-in-out;
		}

		&.active {
			border-bottom: none;
		}

		&.active .slide {
			transform: scaleY(1);
			height: auto;
			opacity: 1;
		}

		li > li {
			div i {
				height: 51px;
				width: 51px;
			}
		}
	}

	&--list-item {
		display: flex;
		padding: 20px 0px;
		border-bottom: 1px solid $clr-brand-light-grey-2;

		&.noBorder {
			border-bottom: none;
		}
	}

	&--searchbar {
		i {
			color: $clr-brand-blue;
		}
	}

	&--search {
		border: none;
		height: 56px;
		outline: none;
		font-size: 16px;
		font-weight: normal;
		line-height: 26px;
		color: $clr-brand-darkest-grey;

		&::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: $clr-brand-blue;
			opacity: 1;
			/* Firefox */
			font-weight: 600;
		}

		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: $clr-brand-blue;
			font-weight: 600;
		}

		::-ms-input-placeholder {
			/* Microsoft Edge */
			color: $clr-brand-blue;
			font-weight: 600;
		}
	}
}
</style>
