import axios from 'axios'

/**
 * Don't remove any existing code here (unless you're 100% sure what you're doing)!
 */
axios.interceptors.request.use(
	async (config) => {
		if (window.keycloak && window.keycloak.token) {
			const tokenExpires = Math.round(
				window.keycloak?.tokenParsed?.exp || 0 + (window.keycloak?.timeSkew || 0) - new Date().getTime() / 1000,
			)
			if (tokenExpires < 10) {
				console.log('[axios] Token expires soon. Updating token before sending request')
				await window.updateKeycloakToken()
			} else {
				console.log('[axios] Token still valid. No need to refresh before sending request')
			}
			config.headers.Authorization = `Bearer ${window.keycloak.token}`
		}

		if (sessionStorage.getItem('actForMemberId') !== null) {
			config.headers['X-Act-For-Member-Id'] = sessionStorage.getItem('actForMemberId')
		} else {
			delete config.headers['X-Act-For-Member-Id']
		}

		return config
	},
	(error) => Promise.reject(error),
)

export default axios
