import { ref } from 'vue'
import { defineStore } from 'pinia'
import * as api from '@/services'
import type { Vc } from '@/types/stores/vc'

export const useVCStore = defineStore('vc', () => {

	const vc = ref<Vc>(null)
	const isFetchingData = ref<boolean>(false)

	async function getVC() {
		isFetchingData.value = true
		api.getVC().then((response) => {
			vc.value = response.data
			isFetchingData.value = false
		})
	}

	return {
		vc,
		isFetchingData,
		getVC,
	}
})

